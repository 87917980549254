.label-lg{
    height: auto!important;
}

#identificador.form-control{
    min-width: 70px;
}

#descripcion{
    min-width: 300px;
}