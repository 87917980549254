.gtco-news .owl-carousel .card a,
#gtco-footer .submit-button {
    background-image: -moz-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%);
    background-image: -webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%);
    background-image: -ms-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%);
    font-size: 18px;
    color: #fff;
    border-radius: 50px;
    padding: 5px 30px;
    display: inline-block;
    text-transform: uppercase;
    padding-right: 5px;
    text-decoration: none !important;
}

.gtco-features a .fa,
.gtco-news .owl-carousel .card a .fa,
#gtco-footer .submit-button .fa {
    background: #fff;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    color: #37eaf9;
    font-size: 22px;
    text-align: center;
    padding-top: 5px;
    margin-left: 15px;
}

.gtco-features h2 {
    color: #000000;
    font-size: 36px;
    margin-bottom: 30px;
}

.gtco-features svg {
    position: absolute;
    margin-left: -100px;
    margin-top: 100px;
}

@media (max-width: 767px) {
    .gtco-features svg {
        margin: 0 auto;
        margin-top: 200px;
    }
}

.gtco-features .col-lg-4 p {
    max-width: 320px;
    margin-bottom: 30px;
}

.gtco-features .col-cards {
    padding: 10px 100px;
}

@media (max-width: 767px) {
    .gtco-features .col-cards {
        padding: 10px 0;
    }
}

.gtco-features .col-cards .row .col-md-6:first-child {
    margin-top: 150px;
}


.gtco-features .col-cards .card {
    background: #fff;
    margin: 30px 5px;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.08);
}

.gtco-features .col-cards .card .oval {
    background: linear-gradient(to right, #f1f6fd, #f0fbfe);
    padding: 10px;
    width: 150px;
    border-radius: 50%;
    margin: auto;
    transform: rotate(20deg);
    margin-top: 20px;
    height: 105px;
}

.gtco-features .col-cards .card .card-img-top {
    max-width: 0.8in;
    margin: 0 auto;
    transform: rotate(-20deg);
}

.gtco-features .col-cards .card h3 {
    font-size: 1.4rem;
    color: #0073f0;
}

.gtco-features .col-cards .card p {
    font-size: 16px;
}

.gtco-features-list {
    margin-top: 100px;
}

.gtco-features-list .media {
    margin: 20px 0;
}

.gtco-features-list .oval {
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    height: 60px;
    width: 60px;
}

@media (max-width: 750px) {
    .gtco-features .col-cards .card {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .gtco-features svg {
        display: none;
    }

    .gtco-features .col-cards .row .col-md-6:first-child {
        margin-top: 0 !important;
    }
}

.gtco-features-list .oval .align-self-start {
    width: 100%;
    padding: 3px;
}

.gtco-features-list .media-body {
    font-size: 16px;
    color: #666666;
    padding-right: 20px;
}

.gtco-features-list .media-body h5 {
    margin-bottom: 20px !important;
    font-size: 22px;
}

.tb-contacto {
    td {
        padding-top: 0;
        padding-bottom: 0;
        height: 2rem;
    }
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue {
    border-bottom-color: transparent !important;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link {
    border-bottom-color: #007af0;
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-text {
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon i {
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.p-sticky-card {
    position: sticky;
    top: 130px;
    z-index: 1;
}

.btn.btn-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-primary2.active,
.btn.btn-light-primary2.show,
.btn.btn-light-primary2:active:not(.btn-active),
.btn.btn-light-primary2:focus:not(.btn-active),
.btn.btn-light-primary2:hover:not(.btn-active),
.show>.btn.btn-light-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color: #009ef7 !important
}

.btn.btn-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-light-primary2 i {
    color: #009ef7
}

.btn.btn-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color: #009ef7
}

.btn.btn-primary2 i {
    color: #fff
}

.btn.btn-light-primary2:hover {
    i {
        color: #fff;
    }
}

.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-primary2,
.btn-check:checked+.btn.btn-primary2,
.btn.btn-primary2.active,
.btn.btn-primary2.show,
.btn.btn-primary2:active:not(.btn-active),
.btn.btn-primary2:focus:not(.btn-active),
.btn.btn-primary2:hover:not(.btn-active),
.show>.btn.btn-primary2 {
    color: #fff;
    border-color: #0095e8;
    background-color: #0095e8
}

.btn-check:active+.btn.btn-primary2 i,
.btn-check:checked+.btn.btn-primary2 i,
.btn.btn-primary2.active i,
.btn.btn-primary2.show i,
.btn.btn-primary2:active:not(.btn-active) i,
.btn.btn-primary2:focus:not(.btn-active) i,
.btn.btn-primary2:hover:not(.btn-active) i,
.show>.btn.btn-primary2 i {
    color: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-primary2.dropdown-toggle:after,
.btn.btn-primary2.active.dropdown-toggle:after,
.btn.btn-primary2.show.dropdown-toggle:after,
.btn.btn-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.btn.btn-hover-primary2:hover:not(.btn-text),
.btn.btn-hover-primary2:focus:not(.btn-text),
.btn.btn-hover-primary2.focus:not(.btn-text) {
    color: #FFFFFF !important;
    background-color: #009ef7 !important;
    border-color: #009ef7 !important;
}

.btn.btn-hover-primary2:hover:not(.btn-text) i,
.btn.btn-hover-primary2:focus:not(.btn-text) i,
.btn.btn-hover-primary2.focus:not(.btn-text) i {
    color: #FFFFFF !important;
}

.text-primary2 {
    color: #007af0 !important;
}

.svg-icon.svg-icon-primary2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #007af0
}

.svg-icon.svg-icon-primary2 svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.svg-icon.svg-icon-success2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #50cd89
}

.svg-icon.svg-icon-success2 svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2,
.btn-check:checked+.btn.btn-active-light-primary2,
.btn.btn-active-light-primary2.active,
.btn.btn-active-light-primary2.show,
.btn.btn-active-light-primary2:active:not(.btn-active),
.btn.btn-active-light-primary2:focus:not(.btn-active),
.btn.btn-active-light-primary2:hover:not(.btn-active),
.show>.btn.btn-active-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff
}

.btn-check:active+.btn.btn-active-light-primary2 i,
.btn-check:checked+.btn.btn-active-light-primary2 i,
.btn.btn-active-light-primary2.active i,
.btn.btn-active-light-primary2.show i,
.btn.btn-active-light-primary2:active:not(.btn-active) i,
.btn.btn-active-light-primary2:focus:not(.btn-active) i,
.btn.btn-active-light-primary2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-primary2 i {
    color: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn.btn-active-light-primary2.active.dropdown-toggle:after,
.btn.btn-active-light-primary2.show.dropdown-toggle:after,
.btn.btn-active-light-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-primary2.dropdown-toggle:after {
    color: #009ef7
}

.btn.btn-active-light-primary2.btn-outline:not(.btn-outline-default) {
    border-color: #009ef7 !important
}

.btn.btn-color-primary2 {
    color: #009ef7
}

.btn.btn-color-primary2 i {
    color: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-primary2.dropdown-toggle:after {
    color: #009ef7
}

.symbol.symbol-light-primary2 .symbol-label {
    color: #009ef7;
    background-color: #ecf8ff;
}

.symbol.symbol-light-success2 .symbol-label {
    color: #26A69A;
    background-color: #e4f8f7;
}

.table-notas thead {
    font-weight: 600;
    color: #212121 !important;
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.table-notas td {
    border-top: 1px dashed #ECF0F3;
}

.btn.btn-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn.btn-danger i {
    color: #fff
}

.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-danger.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-danger,
.btn-check:checked+.btn.btn-danger,
.btn.btn-danger.active,
.btn.btn-danger.show,
.btn.btn-danger:active:not(.btn-active),
.btn.btn-danger:focus:not(.btn-active),
.btn.btn-danger:hover:not(.btn-active),
.show>.btn.btn-danger {
    color: #fff;
    border-color: #d9214e;
    background-color: #d9214e
}

.btn-check:active+.btn.btn-danger i,
.btn-check:checked+.btn.btn-danger i,
.btn.btn-danger.active i,
.btn.btn-danger.show i,
.btn.btn-danger:active:not(.btn-active) i,
.btn.btn-danger:focus:not(.btn-active) i,
.btn.btn-danger:hover:not(.btn-active) i,
.show>.btn.btn-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-danger.dropdown-toggle:after,
.btn.btn-danger.active.dropdown-toggle:after,
.btn.btn-danger.show.dropdown-toggle:after,
.btn.btn-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-danger.dropdown-toggle:after {
    color: #fff
}

.btn.btn-light-danger {
    color: #f1416c;
    border-color: #fff5f8;
    background-color: #fff5f8
}

.btn.btn-light-danger i {
    color: #f1416c
}

.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn-check:active+.btn.btn-light-danger,
.btn-check:checked+.btn.btn-light-danger,
.btn.btn-light-danger.active,
.btn.btn-light-danger.show,
.btn.btn-light-danger:active:not(.btn-active),
.btn.btn-light-danger:focus:not(.btn-active),
.btn.btn-light-danger:hover:not(.btn-active),
.show>.btn.btn-light-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-light-danger i,
.btn-check:checked+.btn.btn-light-danger i,
.btn.btn-light-danger.active i,
.btn.btn-light-danger.show i,
.btn.btn-light-danger:active:not(.btn-active) i,
.btn.btn-light-danger:focus:not(.btn-active) i,
.btn.btn-light-danger:hover:not(.btn-active) i,
.show>.btn.btn-light-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-light-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light-danger.dropdown-toggle:after,
.btn.btn-light-danger.active.dropdown-toggle:after,
.btn.btn-light-danger.show.dropdown-toggle:after,
.btn.btn-light-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light-danger.dropdown-toggle:after {
    color: #fff
}

.btn.btn-bg-danger {
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-active-danger,
.btn-check:checked+.btn.btn-active-danger,
.btn.btn-active-danger.active,
.btn.btn-active-danger.show,
.btn.btn-active-danger:active:not(.btn-active),
.btn.btn-active-danger:focus:not(.btn-active),
.btn.btn-active-danger:hover:not(.btn-active),
.show>.btn.btn-active-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-active-danger i,
.btn-check:checked+.btn.btn-active-danger i,
.btn.btn-active-danger.active i,
.btn.btn-active-danger.show i,
.btn.btn-active-danger:active:not(.btn-active) i,
.btn.btn-active-danger:focus:not(.btn-active) i,
.btn.btn-active-danger:hover:not(.btn-active) i,
.show>.btn.btn-active-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-danger.dropdown-toggle:after,
.btn.btn-active-danger.active.dropdown-toggle:after,
.btn.btn-active-danger.show.dropdown-toggle:after,
.btn.btn-active-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-danger.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-active-light-danger,
.btn-check:checked+.btn.btn-active-light-danger,
.btn.btn-active-light-danger.active,
.btn.btn-active-light-danger.show,
.btn.btn-active-light-danger:active:not(.btn-active),
.btn.btn-active-light-danger:focus:not(.btn-active),
.btn.btn-active-light-danger:hover:not(.btn-active),
.show>.btn.btn-active-light-danger {
    color: #f1416c;
    border-color: #fff5f8;
    background-color: #fff5f8
}

.btn-check:active+.btn.btn-active-light-danger i,
.btn-check:checked+.btn.btn-active-light-danger i,
.btn.btn-active-light-danger.active i,
.btn.btn-active-light-danger.show i,
.btn.btn-active-light-danger:active:not(.btn-active) i,
.btn.btn-active-light-danger:focus:not(.btn-active) i,
.btn.btn-active-light-danger:hover:not(.btn-active) i,
.show>.btn.btn-active-light-danger i {
    color: #f1416c
}

.btn-check:active+.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn-check:active+.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-danger.dropdown-toggle:after,
.btn.btn-active-light-danger.active.dropdown-toggle:after,
.btn.btn-active-light-danger.show.dropdown-toggle:after,
.btn.btn-active-light-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn.btn-active-light-danger.btn-outline:not(.btn-outline-default) {
    border-color: #f1416c !important
}

.btn.btn-color-danger {
    color: #f1416c
}

.btn.btn-color-danger i {
    color: #f1416c
}

.btn.btn-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn.btn-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-danger.dropdown-toggle:after {
    color: #f1416c
}

.nav.nav-pills.nav-primary2 .show>.nav-link,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) {
    color: #009ef7;
    background-color: transparent;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-text,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-text {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon i,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon i {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.nav.nav-pills.nav-primary2 .show>.nav-link:after,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active):after {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .nav-link.active {
    color: #ffffff;
    background-color: #0095e8;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-text {
    color: #ffffff;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon i {
    color: #ffffff;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff !important;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.nav.nav-pills.nav-primary2 .nav-link.active:after {
    color: #ffffff;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-text {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon i {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-arrow {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-text {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon i {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-arrow {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active:before {
    background-color: #009ef7;
}

.bg-light-primary2 {
    background-color: #ecf8ff;
}

a.text-hover-primary2,
.text-hover-primary2 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

a.text-hover-primary2:hover,
.text-hover-primary2:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #009ef7 !important;
}

a.text-hover-primary2:hover i,
.text-hover-primary2:hover i {
    color: #009ef7 !important;
}

a.text-hover-primary2:hover .svg-icon svg g [fill],
.text-hover-primary2:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

a.text-hover-primary2:hover .svg-icon svg:hover g [fill],
.text-hover-primary2:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.hoverable:hover a.text-hover-primary2,
.hoverable:hover .text-hover-primary2 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 i,
.hoverable:hover .text-hover-primary2 i {
    color: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 .svg-icon svg g [fill],
.hoverable:hover .text-hover-primary2 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-primary2 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn-success2 {
    color: #000;
    background-color: #26A69A;
    border-color: #26A69A;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)
}

.btn-success2:hover {
    color: #000;
    background-color: #6ad59b;
    border-color: #62d295
}

.btn-check:focus+.btn-success2,
.btn-success2:focus {
    color: #000;
    background-color: #6ad59b;
    border-color: #62d295;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .25rem rgba(68, 174, 116, .5)
}

.btn-check:active+.btn-success2,
.btn-check:checked+.btn-success2,
.btn-success2.active,
.btn-success2:active,
.show>.btn-success2.dropdown-toggle {
    color: #000;
    background-color: #73d7a1;
    border-color: #62d295
}

.btn-check:active+.btn-success2:focus,
.btn-check:checked+.btn-success2:focus,
.btn-success2.active:focus,
.btn-success2:active:focus,
.show>.btn-success2.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .25rem rgba(68, 174, 116, .5)
}

.btn-success2.disabled,
.btn-success2:disabled {
    color: #000;
    background-color: #26A69A;
    border-color: #26A69A
}

.btn.btn-success2 {
    color: #fff;
    border-color: #26A69A;
    background-color: #26A69A
}

.btn.btn-success2 i {
    color: #fff
}

.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-success2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-success2,
.btn-check:checked+.btn.btn-success2,
.btn.btn-success2.active,
.btn.btn-success2.show,
.btn.btn-success2:active:not(.btn-active),
.btn.btn-success2:focus:not(.btn-active),
.btn.btn-success2:hover:not(.btn-active),
.show>.btn.btn-success2 {
    color: #fff;
    border-color: #47be7d;
    background-color: #47be7d
}

.btn-check:active+.btn.btn-success2 i,
.btn-check:checked+.btn.btn-success2 i,
.btn.btn-success2.active i,
.btn.btn-success2.show i,
.btn.btn-success2:active:not(.btn-active) i,
.btn.btn-success2:focus:not(.btn-active) i,
.btn.btn-success2:hover:not(.btn-active) i,
.show>.btn.btn-success2 i {
    color: #fff
}

.btn-check:active+.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-success2.dropdown-toggle:after,
.btn.btn-success2.active.dropdown-toggle:after,
.btn.btn-success2.show.dropdown-toggle:after,
.btn.btn-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-success2.dropdown-toggle:after {
    color: #fff
}

.btn.btn-light-success2 {
    color: #26A69A;
    border-color: #e4f8f7;
    background-color: #e4f8f7
}

.btn.btn-light-success2 i {
    color: #26A69A
}

.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-success2.dropdown-toggle:after {
    color: #26A69A
}

.btn-check:active+.btn.btn-light-success2,
.btn-check:checked+.btn.btn-light-success2,
.btn.btn-light-success2.active,
.btn.btn-light-success2.show,
.btn.btn-light-success2:active:not(.btn-active),
.btn.btn-light-success2:focus:not(.btn-active),
.btn.btn-light-success2:hover:not(.btn-active),
.show>.btn.btn-light-success2 {
    color: #fff;
    border-color: #26A69A;
    background-color: #26A69A
}

.btn-check:active+.btn.btn-light-success2 i,
.btn-check:checked+.btn.btn-light-success2 i,
.btn.btn-light-success2.active i,
.btn.btn-light-success2.show i,
.btn.btn-light-success2:active:not(.btn-active) i,
.btn.btn-light-success2:focus:not(.btn-active) i,
.btn.btn-light-success2:hover:not(.btn-active) i,
.show>.btn.btn-light-success2 i {
    color: #fff
}

.btn-check:active+.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-light-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light-success2.dropdown-toggle:after,
.btn.btn-light-success2.active.dropdown-toggle:after,
.btn.btn-light-success2.show.dropdown-toggle:after,
.btn.btn-light-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light-success2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-active-light-success2,
.btn-check:checked+.btn.btn-active-light-success2,
.btn.btn-active-light-success2.active,
.btn.btn-active-light-success2.show,
.btn.btn-active-light-success2:active:not(.btn-active),
.btn.btn-active-light-success2:focus:not(.btn-active),
.btn.btn-active-light-success2:hover:not(.btn-active),
.show>.btn.btn-active-light-success2 {
    color: #26A69A;
    border-color: #e4f8f7;
    background-color: #e4f8f7
}

.btn-check:active+.btn.btn-active-light-success2 i,
.btn-check:checked+.btn.btn-active-light-success2 i,
.btn.btn-active-light-success2.active i,
.btn.btn-active-light-success2.show i,
.btn.btn-active-light-success2:active:not(.btn-active) i,
.btn.btn-active-light-success2:focus:not(.btn-active) i,
.btn.btn-active-light-success2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-success2 i {
    color: #26A69A
}

.btn-check:active+.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn-check:active+.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-success2.dropdown-toggle:after,
.btn.btn-active-light-success2.active.dropdown-toggle:after,
.btn.btn-active-light-success2.show.dropdown-toggle:after,
.btn.btn-active-light-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-success2.dropdown-toggle:after {
    color: #26A69A
}

.btn.btn-active-light-success2.btn-outline:not(.btn-outline-default) {
    border-color: #26A69A !important
}

.btn.btn-color-success2 {
    color: #26A69A
}

.btn.btn-color-success2 i {
    color: #26A69A
}

.btn.btn-color-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn.btn-color-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-success2.dropdown-toggle:after {
    color: #26A69A
}














.btn.btn-color-info2 {
    color: #7239ea
}

.btn.btn-color-info2 i {
    color: #7239ea
}

.btn.btn-color-info2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #7239ea
}

.btn.btn-color-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-info2.dropdown-toggle:after {
    color: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2,
.btn-check:checked+.btn.btn-active-light-info2,
.btn.btn-active-light-info2.active,
.btn.btn-active-light-info2.show,
.btn.btn-active-light-info2:active:not(.btn-active),
.btn.btn-active-light-info2:focus:not(.btn-active),
.btn.btn-active-light-info2:hover:not(.btn-active),
.show>.btn.btn-active-light-info2 {
    color: #7239ea;
    border-color: #f8f5ff;
    background-color: #f8f5ff;
}

.btn-check:active+.btn.btn-active-light-info2 i,
.btn-check:checked+.btn.btn-active-light-info2 i,
.btn.btn-active-light-info2.active i,
.btn.btn-active-light-info2.show i,
.btn.btn-active-light-info2:active:not(.btn-active) i,
.btn.btn-active-light-info2:focus:not(.btn-active) i,
.btn.btn-active-light-info2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-info2 i {
    color: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-info2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-info2.dropdown-toggle:after,
.btn.btn-active-light-info2.active.dropdown-toggle:after,
.btn.btn-active-light-info2.show.dropdown-toggle:after,
.btn.btn-active-light-info2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-info2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-info2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-info2.dropdown-toggle:after {
    color: #7239ea
}

.btn.btn-active-light-info2.btn-outline:not(.btn-outline-default) {
    border-color: #7239ea !important
}