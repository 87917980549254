.login.login-4 .login-container .login-content {
    width: 94%
}

.login.login-4 .login-container .login-content .wizard-nav {
    padding: 0
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step {
    padding: .75rem 0;
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    margin-bottom: 1.5rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    width: 46px;
    height: 46px;
    border-radius: 12px;
    background-color: #f3f6f9;
    margin-right: 1rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
    display: none;
    font-size: 1.4rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
    font-weight: 600;
    color: #3f4254;
    font-size: 1.35rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #181c32;
    font-weight: 600;
    font-size: 1.24rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
    color: #b5b5c3;
    font-size: .925rem
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    background-color: #388e3c47
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
    color: #388E3C;
    display: inline-block
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-number {
    display: none
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title {
    color: #b5b5c3
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-desc {
    color: #d1d3e0
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    background-color: #388e3c47
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-check {
    color: #388E3C;
    display: none
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-number {
    color: #388E3C
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
    color: #181c32
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-desc {
    color: #b5b5c3
}

.login.login-4 .login-container .login-content.login-content-signup {
    width: 700px
}

.login.login-4 .login-aside {
    width: 920px
}

.login.login-4 .login-aside .login-conteiner {
    height: 100%
}

@media (min-width:992px) {
    .login.login-4 .login-aside .login-conteiner {
        min-height: 610px !important;
        object-fit: cover;
        background-size: cover;
    }
}

@media (min-width:992px) and (max-width:1399.98px) {
    .login.login-4 .login-aside .login-conteiner {
        min-height: 420px !important;
        object-fit: cover;
        background-size: cover;
    }
}

@media (max-width:991.98px) {
    .login.login-4 .login-container .wizard-nav {
        padding: 0;
        -ms-flex-line-pack: center;
        align-content: center
    }

    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
        margin-bottom: .5rem
    }

    .login.login-4 .login-aside {
        width: 100% !important
    }

    .login.login-4 .login-aside .login-conteiner {
        min-height: 210px !important;
        object-fit: cover;
        background-size: cover;
    }
}

@media (max-width:575.98px) {
    .login.login-4 .login-container {
        width: 100%
    }

    .login.login-4 .login-container .wizard-nav .wizard-steps {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
        width: 100%
    }

    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .svg-icon {
        display: none
    }

    .login.login-4 .login-container .login-content {
        width: 100%
    }

    .login.login-4 .login-container .login-content.login-content-signup {
        width: 100%
    }

    .login.login-4 .login-aside {
        width: 100% !important
    }
}

@media (max-width:1800px) {
    .login.login-4 .login-aside {
        width: 600px
    }
}


@media screen and (max-width: ($xxxl)) {
    .max-h-img {
        max-height: 250px !important;
    }

    .login-form {
        width: 50%;
    }
}

@media screen and (max-width: ($xxl)) {
    .max-h-img {
        max-height: 70px !important;
    }

    .login-form {
        width: 55%;
    }
}

@media screen and (max-width: ($xl)) {
    .max-h-img {
        max-height: 50px !important;
    }

    .login-form {
        width: 67%;
    }
}

@media screen and (max-width: ($lg)) {
    .max-h-img {
        max-height: 32px !important;
    }

    .login-form {
        width: 80%;
    }
}

@media screen and (max-width: ($md)) {
    .max-h-img {
        max-height: 50px !important;
    }

    .login-form {
        width: 80%;
    }
}

@media screen and (max-width: ($sm)) {
    .max-h-img {
        max-height: 45px !important;
    }

    .login-form {
        width: 80%;
    }
}

@media screen and (max-width: ($xs)) {
    .max-h-img {
        max-height: 30px !important;
    }

    .login-form {
        width: 80%;
    }
}

@media screen and (max-width: ($xxs)) {
    .max-h-img {
        max-height: 29px !important;
    }

    .login-form {
        width: 100%;
    }
}

@include down-screen($xxxs) {
    .max-h-img {
        max-height: 18px !important;
    }

    .login-form {
        width: 100%;
    }
}