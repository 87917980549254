.container{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.container > div {
    margin-top: 1.5rem;
}

.container div span{
    font-weight: bold;

}

.btnAprobar button{
    background-color: green;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

.sendButton {
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.sendButton:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.sendButton:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.div1 {
    grid-area: 1 / 1 / 2 / 2;
}

.div2 {
    grid-area: 1 / 2 / 2 / 3;
}

.div3 {
    grid-area: 2 / 1 / 3 / 2;
}

.div4 {
    grid-area: 2 / 2 / 3 / 3;
}

.div5 {
    grid-area: 3 / 1 / 4 / 2;
}

.div6 {
    grid-area: 3 / 2 / 4 / 3;
}

.div7 {
    grid-area: 4 / 1 / 5 / 2;
}

.div8 {
    grid-area: 4 / 2 / 5 / 3;
}

