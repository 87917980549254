$xxxs:320px;
$xxs:360px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1400px;
$xxl: 1920px;
$xxxl: 4096px;

@mixin screen($media){
    @media (min-width: $media){
        @content;
    }
}

@mixin down-screen($media){
    @media (max-width: $media){
        @content;
    }
}
