
#dropdown-button-newtable {
    color: #2171c1;
    background-color: transparent;
    border-color: transparent;
    height: 30px;
    width: 30px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    &:hover {
        background-color: #2171c1;
        border-color: #2171c1;
        color: #ffffff !important;
    }
    &:active {
        background-color: #2171c1;
        border-color: #2171c1;
        color: #ffffff !important;
    }
    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #2171c1;
        &:hover { color: #fff; }
        &:active { color: #fff; }
    }
    &:hover i {
        color: #ffffff !important; 
    }
}

#dropdown-newtable-options {
    color: #2171c1;
    background-color: #e1f0ff;
    border-color: #e1f0ff;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.2px;
    &:hover {
        background-color: #2171c1;
        border-color: #2171c1;
        color: #ffffff !important;
    }
    &:active {
        background-color: #2171c1;
        border-color: #2171c1;
        color: #ffffff !important;
    }
    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #2171c1;
        &:hover { color: #fff; }
        &:active { color: #fff; }
    }
    &:hover i {
        color: #ffffff !important; 
    }
}

#dropdown-proyectos {
    color: #7e8299;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 0.4rem 0.6rem;
    &:hover {
        background-color: #f1faff;
        border-color: #f1faff;
        color: #009ef7 !important;
    }
    &:active {
        background-color: #f1faff;
        border-color: #f1faff;
        color: #009ef7 !important;
    }
    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #7e8299;
        &:hover { color: #009ef7; }
        &:active { color: #009ef7; }
    }
    &:hover i {
        color: #009ef7 !important; 
    }
}

#dropdown-proyectos-light-primary {
    color: #009ef7;
    background-color: #f1faff;
    border-color: #f1faff;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 0.4rem 0.6rem;
    &:hover {
        background-color: #009ef7;
        border-color: #009ef7;
        color: white !important;
    }
    &:active {
        background-color: #f1faff;
        border-color: #f1faff;
        color: white !important;
    }
    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #009ef7;
        &:hover { color: white; }
        &:active { color: white; }
    }
    &:hover i {
        color: white !important; 
    }
}
.toolbar-dropdown{
    .dropdown-menu.show{
        padding: 0;
    }
}


.dropdown-danger {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #F64E60;
        text-decoration: none;
        background-color: #FFE2E5;
    }
    &:active {
        color: #F64E60 ;
        text-decoration: none;
        background-color: #FFE2E5;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-danger.dropdown-item.active{
    color: #F64E60;
    text-decoration: none;
    background-color: #FFE2E5;
    i { color: #F64E60;}
}


.dropdown-success {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #388E3C;
        text-decoration: none;
        background-color: #E8F5E9;
    }
    &:active {
        color: #388E3C ;
        text-decoration: none;
        background-color: #E8F5E9;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-success.dropdown-item.active{
    color: #388E3C;
    text-decoration: none;
    background-color: #E8F5E9;
    i { color: #388E3C;}
}


.dropdown-primary {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #2171c1;
        text-decoration: none;
        background-color: #E1F0FF;
    }
    &:active {
        color: #2171c1 ;
        text-decoration: none;
        background-color: #E1F0FF;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-primary.dropdown-item.active{
    color: #2171c1;
    text-decoration: none;
    background-color: #E1F0FF;
    i { color: #2171c1;}
}

.dropdown-info {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #8950FC;
        text-decoration: none;
        background-color: #eee5ff;
    }
    &:active {
        color: #8950FC ;
        text-decoration: none;
        background-color: #eee5ff;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-info.dropdown-item.active{
    color: #8950FC;
    text-decoration: none;
    background-color: #eee5ff;
    i { color: #8950FC;}
}

.dropdown-dark {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #212121;
        text-decoration: none;
        background-color: #f3f6f9;
    }
    &:active {
        color: #212121;
        text-decoration: none;
        background-color: #f3f6f9;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-dark.dropdown-item.active{
    color: #212121;
    text-decoration: none;
    background-color: #f3f6f9;
    i { color: #212121;}
}

.dropdown-warning {
    font-weight: 500;
    color: #7f7f7f;
    padding: 6px 15px;
    &:hover {
        color: #ffa800;
        text-decoration: none;
        background-color: #fff4de;
    }
    &:active {
        color: #ffa800 ;
        text-decoration: none;
        background-color: #fff4de;
    }
    span.navi-text{
        padding-left: 5px;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 13px;
    }
    i{
        color:#B5B5C3
    }
}

.dropdown-warning.dropdown-item.active{
    color: #ffa800;
    text-decoration: none;
    background-color: #fff4de;
    i { color: #ffa800;}
}


#dropdown-white {
    color: #212121;
    background-color: white;
    border-color: white;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 0.4rem 0.6rem;
    &:hover {
        background-color: #f1faff;
        border-color: #f1faff;
        color: #009ef7 !important;
    }
    &:active {
        background-color: #f1faff;
        border-color: #f1faff;
        color: #009ef7 !important;
    }
    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: #212121;
        &:hover { color: #009ef7; }
        &:active { color: #009ef7; }
    }
    &:hover i {
        color: #009ef7 !important; 
    }
}


.dropdown-historial-presupuestos{
    .dropdown-toggle.btn.btn-primary {
        background-color: #E8F5E9;
        border: transparent;
        height: 30px;
        width: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after{
            color: #388E3C !important;
            font-size: 0.7rem;
            margin-left: 0;
            font-weight: 600;
        }
        &:active {
            background-color: #388E3C !important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .item-contratados{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .item-contratados.dropdown-item:hover {
                color: #388E3C;
                background-color: #E8F5E9;
                i {
                    color: #388E3C;
                }
            }
        }
    }
}