.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container div{
    width: 1.7rem;
    height: 1.8rem;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    margin-left: 1px;
}

.containerEliminado {
    width: 12rem;
    height: 1.8rem;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    background-color: rgba(242, 108, 79, 0.85);
}

.container > div > span {
    margin-top: 5px;
    cursor: pointer;
}

.grayBox{
    background: rgba(0, 0, 0, 0.25);
}

.greenBox{
    background-color: #1693A5;
}

.yellowBox{
    background-color: #FFD549;
}

.redBox{
    background-color: rgba(242, 108, 79, 0.85);;
}

.modalContainer{
    margin-top: 1rem;
    width: 100%;
    height: 100%;
}

.circleStatus{
    width: 2rem;
    height: 1.8rem;
    border-radius: 50%;
    color: white;
    margin-left: 1px;
    background-color: #1693A5;
}