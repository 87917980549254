.text-color{
    &__gold{
        color: #000;
    }
    &__gold-80{
        color: $gold-80
    }

    &__dark-blue{
        color: $dark-blue;
        a{
            color: $dark-blue;
            &:hover{
                color: $gold;
                text-decoration: none;
            }
        }
    }
    &__dark-blue-80{
        color: $dark-blue-80
    }

    &__blue{
        color: $blue
    }
    &__blue-80{
        color: $blue-80
    }
}

.title{
    font-weight: bold;
    @include font-size(40px);
    letter-spacing: 1.5px;
}

.subtitle{
    font-weight: 500;
    @include font-size(30px);
    letter-spacing: 1px;
}

.small{
    font-weight: 100;
    @include font-size(15px);
    letter-spacing: 0px;
}

.text-decoration-line-through{
    text-decoration: line-through;
}