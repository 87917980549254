.rdrInputRanges {
    display: none !important;
}

.rdrStaticRange {
    text-transform: uppercase;
    border-bottom: 0px !important;
}

.rdrDateDisplayItem input {
    text-transform: uppercase;
}

.rdrMonthAndYearPickers select {
    text-transform: uppercase;
}

.rdrMonthAndYearPickers select:hover {
    background-color: #EFF2F7 !important;
}


.rdrDateRangePickerWrapper {
    @include down-screen($md) {
        display: block !important;
        text-align: center !important;
    }

    @include screen($lg) {
        padding: 0.75rem 0.5rem !important;
    }

    @include screen($xxl) {
        padding: 1.75rem 1.5rem !important;
    }

    border: 3px solid #F3F6F9;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}

.rdrCalendarWrapper {
    @include down-screen($md) {
        font-size: 10.5px !important;
    }

    @include screen($lg) {
        font-size: 12px !important;
    }

    @include screen($xxl) {
        font-size: 13px !important;
    }
}

@include down-screen($md) {
    .rdrCalendarWrapper {
        &.rdrDateRangeWrapper {
            margin-top: 0.5rem;
            font-size: 9.8px !important;
        }
    }
}

@include down-screen($lg) {
    .rdrCalendarWrapper {
        &.rdrDateRangeWrapper {
            width: 283px;
        }
    }
}

@include down-screen($xxl) {
    .rdrCalendarWrapper {
        &.rdrDateRangeWrapper {
            width: 300px;
        }
    }

    #estado-resultados {
        .rdrCalendarWrapper {
            &.rdrDateRangeWrapper {
                width: 283px;
            }
        }
    }
}

@include down-screen($xs) {
    .rdrCalendarWrapper {
        &.rdrDateRangeWrapper {
            width: 246px !important;
            padding: 6px;
        }
    }

    .rdrNextButton i {
        margin: 4px !important;
    }

    .rdrNextPrevButton i {
        margin: 4px !important;
    }

    button.rdrNextPrevButton.rdrPprevButton {
        margin-left: 0px;
    }
}

.rdrDefinedRangesWrapper {
    width: 135px !important;
    align-self: center;
    margin-right: 13px;
    border-right: solid 4px #eff2f7 !important;

    @include down-screen($xs) {
        border: 0px !important;
    }

    @include down-screen($md) {
        border: 1px solid #eff2f7;
        width: 100% !important;
    }

    @include screen($xxl) {
        width: 147px !important;
        margin-right: 33px;
    }
}

// Inicio: Header opciones
.rdrStaticRangeLabel {
    @include down-screen($xs) {
        text-align: center !important;
    }

    @include down-screen($sm) {
        text-align: center !important;
    }

    color: #849095;
}

// Border Hover
span.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
    color: #2171c1 !important;
}

// .rdrDayToday .rdrDayNumber span:after{
//     background: red!important;
// }

.rdrPprevButton i {
    border-color: transparent rgb(53, 126, 199) transparent transparent !important;
}

.rdrNextButton i {
    border-color: transparent transparent transparent rgb(53, 126, 199) !important;
}

.rdrNextPrevButton:hover {
    background: #e1f0ff !important;
}

.rdrDayDisabled {
    background-color: #FFF4DE !important;

    span {
        color: #FFA800 !important;
    }
}

.input-calendar {
    .rdrDayDisabled {
        background-color: #D0D0D0 !important;

        span {
            color: white !important;
        }
    }
}

.rdrMonthAndYearWrapper {
    height: 50px;
    padding-top: 0 !important;
}

div#info.rdrMonths.rdrMonthsVertical {
    font-size: 11px;
}

@media screen and (max-width: 1280px) {
    .col-md-4 {
        .rdrCalendarWrapper {
            @include down-screen($xs) {
                font-size: 10px !important;
            }

            font-size: 10.7px !important;
        }

        .rdrMonths.rdrMonthsHorizontal,
        span.rdrMonthAndYearPickers {
            font-size: 12px;

            @include down-screen($xs) {
                font-size: 10px !important;
            }
        }

        .rdrMonth {
            @include down-screen($xs) {
                padding: 0px !important;
            }
        }

        .rdrMonths.rdrMonthsVertical {
            @include down-screen($xs) {
                font-size: 9.7px;
            }
        }
    }
}

.col-md-6 {
    .calendar-tickets {
        @media screen and (min-width: 768px) {

            .rdrCalendarWrapper,
            .rdrMonth {
                width: -webkit-fill-available;
            }
        }

        @media screen and (min-width: 992px) {

            .rdrCalendarWrapper,
            .rdrMonth {
                width: auto;
            }
        }
    }
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0px;
}

.rdrMonth {
    padding: 0 0.833em 0 0.833em;
}