.messages {
    .message {
        &.from-me {
            margin-bottom: 0px;
            .message-body {
                background-color: #E1F0FF;
                color: #2171c1;
                padding: 5px 20px;
                margin-bottom: 10px;
            }
            .username {
                display: none;
            }
        }
    }

    .message-body {
        font-size: 1.08rem;
        padding: 7px 20px;
        font-weight: 500;
        text-align: left;
        display: inline-block;
        background-color: #fbeff7;
        color: #e44e93;
        border: 1px;
        display: block;
    }

    .username {
        font-weight: bold;
        font-size: 0.9rem;
        padding: 7px 20px;
        color: #e44e93;
        margin-bottom: 5px;
    }

    .strong {
        font-weight: 700;
        font-size: 0.9rem;
        padding: 7px 20px;
        color: #e44e93;
        margin-bottom: 5px;
    }
}