.error {
    color: red;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.container>div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
}

.containerFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.maxWidthInput {
    min-width: 10rem;
    max-width: 20rem;
}

.maxWidthInputText {
    width: 17rem;
}

.sendButton {
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.sendButton:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.sendButton:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.container_insumos{
    height: 8rem;
    margin-top: 1rem;
}

.control_insumos{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 5rem;
}

.control_input{
    width: 10rem;
    margin-right: 1rem;
}

.control_boton{
    margin-top: -.2rem;
    display: flex;
    justify-content: end;
}