/*  ==============  TIMELINE TICKETS  ==============  */

// COLOR DE TEXTOS
.header-ticket-espera{
    color: #de6c55;
    background: #ffe3d2;
}
.header-ticket-revision{
    color: #f56369;
    background: #ffdadf;
}
.header-ticket-aprobacion{
    color: #47657c;
    background: #daf0ff;
}
.header-ticket-proceso{
    color: #807999;
    background: #dcdfff;
}
.header-ticket-pendiente-pago{
    color: #d0708e;
    background: #fedae6;
}
.header-ticket-terminado{
    color: #649baa;
    background: #E0F2F1;
}
.header-ticket-aceptado, .header-ticket-rechazado{
    color: #4da4d1;
    background: #E1F5FE;
}

// DOT HOVER
.status.dot-espera-ticket:hover:before { 
    box-shadow: 0 0 6px 0 #de6c55;
}
.status.dot-revision-ticket:hover:before {
    box-shadow: 0 0 6px 0 #f56369;
}
.status.dot-aprobacion-ticket:hover:before {
    box-shadow: 0 0 6px 0 #47657c;
}
.status.dot-proceso-ticket:hover:before {
    box-shadow: 0 0 6px 0 #807999;
}
.status.dot-pendiente-pago-ticket:hover:before {
    box-shadow: 0 0 6px 0 #d0708e;
}
.status.dot-terminado-ticket:hover:before {
    box-shadow: 0 0 6px 0 #649baa;
}
.status.dot-rechazado-ticket:hover:before, .status.dot-aceptado-ticket:hover:before  {
    box-shadow: 0 0 6px 0 #4da4d1;
}

// TIMELINE ESTATUS
.timeline-estatus {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.li {
    transition: all 200ms ease-in;
    margin-top: 20px;
}

.status {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    border-top: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
    white-space: nowrap;
}

.status h4 {
    font-weight: 300;
    font-size: 0.8rem;
    margin-bottom: 0;
    line-height: 2;
    color:#3f4254
}

.status:before {
    content: '✔';
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 25px;
    border: 2px solid #ddd;
    position: absolute;
    top: -12px;
    left: 42%;
    transition: all 200ms ease-in;
    display: flex;
    font-size: 1.1rem;
    justify-content: center;
    color: white;
}

// COMPLETE ESTATUS


/*  -----  ESTATUS: EN ESPERA  -----  */
.li.complete_espera .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #cd583d, #d05c42, #d45f46, #d7634a, #da674f, #dd6852, #df6854, #e26957, #e56659, #e7635b, #ea605d, #df6c55);
}
.li.complete_espera .status:before {
    background-color: #de6c55;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: EN REVISIÓN  -----  */
.li.complete_revision .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #de6c55, #e36a58, #e7695b, #ec675f, #f06563, #f26379, #f56369, #f56369, #f16571, #e16c7f,#e47587, #b67aa1, #a182b0, #4ea3d0);
}
.li.complete_revision .status:before {
    background-color: #f56369;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: ACEPTADO  -----  */
.li.complete_aceptado .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #4da4d1, #4fa0cc, #509cc6, #5199c1, #5295bc, #5290b5, #528aad, #5185a6, #4f7d9b, #4c7591, #4a6d86, #47657c);
}
.li.complete_aceptado .status:before {
    background-color: #4da4d1;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: RECHAZADO  -----  */
.li.complete_rechazado .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #4da4d1, #4fa0cc, #509cc6, #5199c1, #5295bc, #5290b5, #528aad, #5185a6, #4f7d9b, #4c7591, #4a6d86, #47657c);
}
.li.complete_rechazado .status:before {
    background-color:  #4da4d1;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: PENDIENTE  -----  */
.li.complete_pendiente .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #47657c, #4e6780, #566983, #5e6b86, #656d88, #6a6f8a, #6e708c, #73728e, #767491, #797694, #7d7796, #807999);
}
.li.complete_pendiente .status:before {
    background-color: #47657c;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: EN PROCESO  -----  */
.li.complete_proceso .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #807999, #736d8a, #67627b, #5b566c, #4f4b5e, #524a5c, #544859, #564756, #674f5b, #76585e, #836261, #8d6e63);
}
.li.complete_proceso .status:before {
    background-color: #807999;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: PENDIENTE DE PAGO -----  */
.li.complete_pendiente_pago .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #8d6e63, #8b6c61, #896a5f, #87685d, #85665b, #856a59, #836f57, #7f7458, #758063, #688c77, #5f9591, #649baa);
}
.li.complete_pendiente_pago .status:before {
    background-color: #8d6e63;
    border: none;
    transition: all 200ms ease-in;
}

/*  -----  ESTATUS: TERMINADO-----  */
.li.complete_terminado .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #649baa, #639aa6, #6299a2, #62989e, #62979a, #619496, #609192, #5f8e8e, #5c8889, #5a8384, #577d7f, #55787a);
}
.li.complete_terminado .status:before {
    background-color: #649baa;
    border: none;
    transition: all 200ms ease-in;
}

.li.complete_espera .status h4, .li.complete_revision .status h4, .li.complete_aceptado .status h4, .li.complete_rechazado .status h4, .li.complete_pendiente .status h4 , .li.complete_proceso .status h4,  .li.complete_pendiente_pago .status h4, .li.complete_terminado .status h4 {
    font-weight: 500;
}


/*  ==============  TIMELINE PRESUPUESTO  ==============  */

// COLORES DE TIMELINE
$bg-conceptos-presupuesto:#EFEBE9;
$color-conceptos-presupuesto:#8D6E63;


$bg-volumetrias-presupuesto:#E3F4FC;
$color-volumetrias-presupuesto:#009EF7;


$bg-costos-presupuesto:#EDE7F6;
$color-costos-presupuesto:#BA68C8;


$bg-revision-presupuesto:#FFF7DB;
$color-revision-presupuesto:#F1C40F;


$bg-utilidad-presupuesto:#FFECD7;
$color-utilidad-presupuesto:#FF8B00;


$bg-espera-presupuesto:#ECEFF1;
$color-espera-presupuesto:#78909C;


$bg-aceptado-presupuesto:#E0F2F1;
$color-aceptado-presupuesto:#4DB6AC;


$bg-rechazado-presupuesto:#FFEBEE;
$color-rechazado-presupuesto:#F64E60;


// COLOR DE TEXTOS
.header-presupuesto-conceptos{
    color: #ff8c2c;
    background: #ffe7d4;
}
.header-presupuesto-volumetrias{
    color: #f8bf3a;
    background: #ffefc9;
}
.header-presupuesto-costos{
    color: #B9D13D;
    background: #f5fdcc;
}
.header-presupuesto-revision{
    color:#52d390;
    background: #cbfde4;
}
.header-presupuesto-utilidad{
    color: #27b8c9;
    background: #cdf4fa;
}
.header-presupuesto-espera{
    color: #0b6ca3;
    background: #cfeafa;
}
.header-presupuesto-aceptado, .header-presupuesto-rechazado, .bg-aceptado-rechazado{
    color: #764ca2;
    background: #e8d7fa;
}
.color-rechazado-presupuesto{
    color:#764ca2;
}
.color-aceptado-presupuesto{
    color:#764ca2;
}

// DOT HOVER
.status.dot-conceptos-presupuesto:hover:before { 
    box-shadow: 0 0 6px 0 #ff8c2c;
}
.status.dot-volumetrias-presupuesto:hover:before {
    box-shadow: 0 0 6px 0 #f8bf3a;
}
.status.dot-costos-presupuesto:hover:before {
    box-shadow: 0 0 6px 0 #B9D13D;
}
.status.dot-revision-presupuesto:hover:before {
    box-shadow: 0 0 6px 0 #52d390;
}
.status.dot-utilidad-presupuesto:hover:before {
    box-shadow: 0 0 6px 0 #27b8c9;
}
.status.dot-espera-presupuesto:hover:before {
    box-shadow: 0 0 6px 0 #0b6ca3;
}
.status.dot-aceptado-presupuesto:hover:before, .status.dot-rechazado-presupuesto:hover:before, .status.dot-aceptado-rechazado-presupuesto:hover:before  {
    box-shadow: 0 0 6px 0 #764ca2;
}

// COMPLETE ESTATUS


/*  -----  ESTATUS: CONCEPTOS  -----  */
.li.complete-conceptos .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #ff831e, #fdbc3e);
}
.li.complete-conceptos .status:before {
    background-color: #ff8c2c;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: VOLUMETRÍAS  -----  */
.li.complete-volumetrias .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #fdbc3e, #b9d13d);
}
.li.complete-volumetrias .status:before {
    background-color: #f8bf3a;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: COSTOS  -----  */
.li.complete-costos .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #bad23f, #52d390)
}
.li.complete-costos .status:before {
    background-color: #B9D13D;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: EN REVISIÓN  -----  */
.li.complete-revision .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #52d390, #27b8c9);
}
.li.complete-revision .status:before {
    background-color: #52d390;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: UTILIDAD  -----  */
.li.complete-utilidad .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #27b8c9, #0b6ca3);
}
.li.complete-utilidad .status:before {
    background-color: #27b8c9;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: EN ESPERA  -----  */
.li.complete-espera .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #0b6ca3, #764ca2);
}
.li.complete-espera .status:before {
    background-color: #0b6ca3;
    border: none;
    transition: all 200ms ease-in;
}
/*  -----  ESTATUS: ACEPTADO Y RECHAZADO-----  */
.li.complete-aceptado .status, .li.complete-rechazado .status, .li.complete-aceptado-rechazado .status {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #764ca2, #653f8c);
}
.li.complete-aceptado .status:before, .li.complete-rechazado .status:before, .li.complete-aceptado-rechazado .status:before {
    background-color: #764ca2;
    border: none;
    transition: all 200ms ease-in;
}

.li.complete-conceptos .status h4,
.li.complete-volumetrias .status h4,
.li.complete-costos .status h4,
.li.complete-revision .status h4,
.li.complete-utilidad .status h4,
.li.complete-espera .status h4,
.li.complete-aceptado .status h4,
.li.complete-rechazado .status h4,
.li.complete-aceptado-rechazado .status h4  {
    font-weight: 500;
}

.ribbon-estatus {
    text-align: center;
    padding: 5px 0;
    width: fit-content;
    letter-spacing: 1.5px;
    font-weight: 800;
    font-size:1rem;
}

.ribbon-tickets{
    background-image: linear-gradient(to right,#de6c55, #d56d98, #a383c1, #6d94c3, #649baa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ribbon-presupuesto{
    background-image: linear-gradient(to right, #27b8c9, #00a3d0, #008acf, #4e6ec1, #764ca2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.input-conceptos{
    background-color: #fff2e8c2!important;
    border: 1px dashed #ff8c2c!important;
    color: #ff8c2c !important;
}
.input-costos{
    background-color: #f6ffc980 !important;
    border: 1px dashed #b7ce3d !important;
    color: #b7ce3d !important;
}
.input-utilidad{
    background-color: #e3fbfee0 !important;
    border: 1px dashed #27b8c9 !important;
    color: #27b8c9 !important;
}

// TIMELINE DASHED

.timeline .timeline-item-dashed {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start
}

.timeline .timeline-line-dashed {
    display: block;
    content: " ";
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(50%);
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: #e4e6ef;
}

.timeline .timeline-icon-dashed {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 1rem
}

.timeline .timeline-content-dashed {
    width: 100%;
    overflow: auto;
    margin-bottom: 1.5rem
}