.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span.disabled-label-span {
    background-color: #D6D6E0!important;
    border-color: #D6D6E0;
}

@import 'gantt';

@import 'style.bundle.css';
@import 'plugins.bundle.css';
@import 'prismjs.bundle.css';
@import 'dark.css';
@import 'light.css';
@import 'wizard-3.css';
@import 'wizard-2.css';
@import 'wizard-6.css';

@import "~rfs/scss";
@import 'mixin';
@import 'general';
@import 'button';
@import 'text';

@import 'table';

@import 'task';

@import 'pdf';
@import './metronic/keenthemes-icons/font/ki';

@import 'select-search';
@import 'calendar';
@import 'toast';
@import 'rangecalendar';
@import 'label';
@import 'ventas';
@import 'messages';
@import 'range_timerangepicker';
@import 'tag_input';
@import 'tag_input_gray';
@import 'tagselectsearch';
@import 'tagselectsearch_gray';
@import 'datepickermulti';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'notification';
@import 'animation';
@import 'margins';
@import 'login';
@import 'dropdown';

@import 'header';
@import 'dropdownSubMenu';
@import 'tablegrid';


@import 'react-crop';
@import 'timeline';
@import 'proyecto';
@import 'tooltip';
