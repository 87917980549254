$blue-plataforma: #2171c1;

// Colores
$dark-blue: #325693;
$blue: #7096c1;
$l-blue: #c7d0df;
$bone: #ecedef;
$gold: #b4a26d;
$gold-20: #b4a26d20;
$gold-40: #b4a26d40;
$gold-60: #b4a26d60;
$gold-80: #b4a26d80;
$bone-20: #ecedef20;
$bone-40: #ecedef40;
$bone-60: #ecedef60;
$bone-80: #ecedef80;
$dark-blue-20: #32569320;
$dark-blue-40: #32569340;
$dark-blue-60: #32569360;
$dark-blue-80: #32569380;
$blue-20: #7096c120;
$blue-40: #7096c140;
$blue-60: #7096c160;
$blue-80: #7096c180;
$l-blue-20: #c7d0df20;
$l-blue-40: #c7d0df40;
$l-blue-60: #c7d0df60;
$l-blue-80: #c7d0df80;

$white-bone: #fdfdfd;
$white-l-blue: #f9fafc;
$white-blue: #f2f5f9;
$white-dark-blue: #eceff5;
$white-gold: #f8f6f1;

$d-white-bone: #F8F8F9;
$d-white-l-blue: #eaedf3;
$d-white-blue: #cbd8e8;
$d-white-dark-blue: #b4c1d7;
$d-white-gold: #e3ddc9;

$black-bone: #161616;
$black-l-blue: #131315;
$black-blue: #0B0E12;
$black-dark-blue: #05080E;
$black-gold: #110F0A;

$l-black-bone: #565757;
$l-black-l-blue: #494c52;
$l-black-blue: #293747;
$l-black-dark-blue: #132036;
$l-black-gold: #423b28;

$scrollbar-color:#252525;
$color-text:#80808F;


// .stay-bottom{
//     position: fixed!important;
//     bottom: 0;
// }
.heightCard{
   min-height: 60vh!important;
  
   .stay-bottom{
    position: absolute!important;
    bottom: 2rem;
    width: 95%!important;
}
}

.hidden {
    transition: all 0.5s;
    width: 0px;
    height: 0px;
    overflow: hidden;

    &.active {
        width: auto;
        height: auto;
    }
}

.nav-item.nav-link.active{
    background: $scrollbar-color!important;
    color: white;
    &:hover{
        color: $color-text
    }
}

.text-gold {
    color: $gold;
}

.text-inein {
    color: #D8005A;
}

.text-no-transform {
    input {
        text-transform: none !important;
    }
    text-transform: none !important;
}

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

.symbol.symbol-light-success2 .symbol-label {
    background-color: #d1e3d7;
}

.font-family-poppins {
    font-family: Poppins, Helvetica, sans-serif;
}

body {
    text-transform: uppercase;
    font-family: Poppins, Helvetica, sans-serif !important;
}

.border-nav {
    border: 3px solid #F3F6F9;
    padding: 1.75rem 1.5rem;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}

a.text-hover-danger2:hover,
.text-hover-danger2:hover {
    color: #cc4b4c !important;
}

.a-hover:hover {
    cursor: pointer;
}

.pdfview {
    @include screen ($lg) {

        height: 418.984px;
    }

    @include screen ($xxl) {

        height: 418.984px;
    }

    width: 88%;
    height: 418.984px;
}

.pdfview-img {
    @include down-screen($xs) {
        width: 80%;
        height: auto;
    }

    width: 100%;
    height: auto;

}

@media screen and (max-width: 992px) {


    #descarga,
    img,
    iframe {
        max-width: 527px;
        max-height: 527px;
    }

}



.white-space {
    white-space: normal !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

@media screen and (max-width: 992px) {
    .tagify__tag>div>* {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        min-width: var(--tag--min-width);
        max-width: var(--tag--max-width);
        -webkit-transition: .8s ease, .1s color;
        transition: .8s ease, .1s color;
    }

}

//Todos los calendarios
.react-datepicker-popper {
    z-index: 2;
}

//Usuarios/Empresa
.img-empresa {
    height: 4rem;
    width: auto;

    @include down-screen($md) {
        height: 3rem;
    }
}

//Proyecto/Proyecto
.card-without-box-shadown {
    box-shadow: 0px 0px 0px 0px rgba(82, 63, 105, 0.05) !important;
}

//login
.bg-white-o-9 {
    background-color: rgba(255, 255, 255, 0.09) !important;
}

//Estilo para ToggleButton usuarios/usuarios -> Permisos
.cczuaK input[type="checkbox"]:checked+label:after {
    font-family: Ki;
    content: "";
    color: #ffffff;
    font-size: 0.65em;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    position: absolute;
    display: -webkit-box;
}

.cczuaK {
    color: #ffffff;
    width: 39px !important;
}

.cczuaK input[type="checkbox"]+label {
    border-radius: 5px !important;
    width: 0px !important;
}

.cczuaK input[type="checkbox"]+label:after {
    border-radius: inherit !important;
}

.cczuaK input[type="checkbox"]+label:before {
    left: 0px !important;
    border-radius: 5px !important;
    width: 39px !important;
}

//Se quita el icono de los inputs
.sin_icono {
    background-image: none !important;
    padding-right: 0 !important;
}

.modal-dialog {
    &.modal-xl {
        @include screen($md) {
            max-width: 85vw !important;
        }
    }
}

// Inicio: Tabla actualización de presupuestos
th.check_desc,
th.clave,
th.descripcion {
    @include screen ($lg) {
        width: 1px;
    }

    @include screen ($xxl) {
        width: 1px;
        padding: 4px;
    }
}

td.check_desc.text-center {
    @include screen ($lg) {
        padding-right: 0px;
    }

    @include screen ($xxl) {
        padding-right: 0px;
    }
}

td.clave.text-center {
    @include screen ($xxl) {
        min-width: 59px;
    }
}

td.descripcion.text-center {
    @include screen ($lg) {
        min-width: 500px;
    }

    /* @include screen ($xxl) {
        min-width: 945px;
    } */
}

// Fin: Tabla actualización de presupuestos

a#tabsAreas-tab-compras,
a#tabsAreas-tab-ventas,
a#tabsAreas-tab-egresos,
a#tabsAreas-tab-ingresos,
a#tabsUsuarios-tab-administrador,
a#tabsUsuarios-tab-empleados,
a#tabsUsuarios-tab-clientes {
    @include down-screen($md) {
        font-size: 11px;
    }
}

//TABLA - PRESUPUESTO DISEÑO

td.dia,
th.dia {
    width: 20px;
}

td.dia,
th.dia {
    @include down-screen($md) {
        width: 100px;
    }
}

td.concepto {
    text-align: justify;
}

#container {
    visibility: hidden;
}

#loader {
    color: #008cff;
    height: 40px;
    left: 45%;
    position: absolute;
    top: 45%;
    width: 30%;
}

.tooltip-inner2 {
    font-size: 0.9rem !important;
}

.w-0 {
    width: 0;
    height: 0;
}

.center_content {
    vertical-align: middle !important;
    margin-left: auto;
    margin-right: auto;
}

//Inicio: Dropdown para: CALENDARIO
div#dropdown-calendario {
    #dropdown-button-drop-left {
        color: #3699FF;
        background-color: #E1F0FF;
        border-color: transparent;
        height: calc(1.35em + 1.1rem + 2px);
        width: calc(1.35em + 1.1rem + 2px);
        justify-content: center;
        display: inline-flex;

        i {
            color: #2171c1;
        }
    }

    a.dropdown-item {
        color: #2171c1;
        font-weight: 600;
    }
}

//Inicio: Dropdown para: TAREAS TAG
button#dropdown-button-tag {
    border: transparent;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0px !important;
    padding-bottom: 1px;

}


span#down-tag {
    .dropdown-menu {
        padding: 0px !important;
        max-height: 250px !important;
        overflow: auto !important;
    }
}

//Inicio: Dropdown para: CRM NUEVO

.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
    display: none !important;
}

//Final: Dropdown para: CALENDARIO para: CALENDARIO

.img-calidad {
    @include down-screen($md) {
        width: 100%;
        margin-top: 2rem;
        height: auto;
    }

    width: 27%;
    height: auto;
}

div#symbol_calidad {
    @include down-screen($md) {
        display: none;
    }
}

div#partida_calidad,
div#tipoT_calidad,
div#fecha_calidad {
    @include down-screen($md) {
        justify-content: start !important;
    }
}

a.dropdown-toggle.nav-link {
    @include down-screen($md) {
        margin-left: 0px !important;
    }
}

a.nav-link {
    @include down-screen($md) {
        margin-left: 0px !important;
    }
}

.pt-65px {
    padding-top: 54px !important;
}

#form-flujo-proyectos {
    @include down-screen($lg) {
        text-align: center !important;
    }
}

#id-row {
    @include down-screen($lg) {
        display: block !important;
    }
}

#col-calendar {
    @include down-screen($lg) {
        flex: none;
        max-width: none;
    }
}

#col-table {
    @include down-screen($lg) {
        flex: none;
        max-width: none;
    }
}

// /presupuesto/conceptos tr select
#kt_datatable_conceptos.dataTable tbody tr.selected {
    color: #ECF0F3 !important;
    background-color: #ECF0F3 !important;
}

table.dataTable.display tbody>tr.selected>.sorting_1 {
    background-color: #ECF0F3 !important;
}

.dataTables_wrapper .dataTable tbody tr.selected td {
    background-color: #ECF0F3 !important;
}

// COLOR PINK
.bg-light-pink {
    background-color: #fbeff7 !important;
}

.text-pink {
    color: #e44e93 !important;
}

a.text-hover-pink:hover,
.text-hover-pink:hover {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    color: #e44e93 !important;
}

// COLOR BROWN
.bg-light-brown {
    background-color: #EFEBE9 !important;
}

.text-brown {
    color: #5D4037 !important;
}

a.text-hover-brown:hover,
.text-hover-brown:hover {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    color: #5D4037 !important;
}

// COLOR ORANGE
.bg-light-orange {
    background-color: #fff2d9 !important;
}

.text-orange {
    color: #D08905 !important;
}

a.text-hover-orange:hover,
.text-hover-orange:hover {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    color: #D08905 !important;
}

// COLOR GREEN
.bg-light-green {
    background-color: #E8F5E9 !important;
}

.text-green {
    color: #388E3C !important;
}

// COLOR GRAY
.bg-light-gray {
    background-color: #ECEFF1 !important;
}

.text-gray {
    color: #5F6A6A !important;
}

a.text-hover-gray:hover,
.text-hover-gray:hover {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    color: #5F6A6A !important;
}

.svg-icon.svg-icon-pink svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E83E8C !important;
}

.svg-icon.svg-icon-pink svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #e6217c !important;
}

//reportes/estado-resultados
#card-accordion {
    @include screen($md) {
        width: 500px !important;
    }

    @include screen($lg) {
        width: 450px !important;
    }

    @include screen($xxl) {
        width: 600px !important;
    }
}

.timeline-badge.bg-danger-50 {
    background: #efd0d9 !important;
}

.timeline.timeline-danger:before {
    background-color: #fbeaec !important;
}


table#esquemas {
    width: 421px;
    table-layout: fixed;
}

table#reportes {
    width: 100%;
    table-layout: fixed;
}

.icon-13px {
    font-size: 13px !important;
}

.icon-14px {
    font-size: 14px !important;
}

.icon-15px {
    font-size: 15px !important;
}

.icon-16px {
    font-size: 16px !important;
}

.text-dark-60 {
    color: #686871 !important;
}

.timeline-label {
    font-size: 0.759rem !important;
}

div#asideMenuScroll {
    min-height: 100%;

    @include screen($xxl) {
        max-height: 989px;
    }
}

.timeline.timeline-6 .timeline-items {
    margin: 0;
    padding: 0;
}

.timeline.timeline-6 .timeline-items .timeline-item {
    margin-left: 0;
    padding: 0;
    position: relative;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media {
    position: absolute;
    top: 0;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
    line-height: 0;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media i {
    font-size: 1.4rem;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media .svg-icon svg {
    height: 24px;
    width: 24px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc {
    padding: 9px 0 0 44px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 32px);
    background-color: #EBEDF3;
    border-radius: 6px;
    top: 40px;
    left: 16px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-primary::before {
    background-color: #3699FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-primary::before {
    background-color: #E1F0FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-secondary::before {
    background-color: #E4E6EF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-secondary::before {
    background-color: #EBEDF3;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-success::before {
    background-color: #388E3C;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-success::before {
    background-color: #E8F5E9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-info::before {
    background-color: #8950FC;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-info::before {
    background-color: #EEE5FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-warning::before {
    background-color: #FFA800;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-warning::before {
    background-color: #FFF4DE;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-danger::before {
    background-color: #F64E60;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-danger::before {
    background-color: #FFE2E5;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-bg-gray-200::before {
    background-color: #ECF0F3;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light::before {
    background-color: #F3F6F9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-light::before {
    background-color: #F3F6F9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-dark::before {
    background-color: #181C32;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-dark::before {
    background-color: #D1D3E0;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-white::before {
    background-color: #ffffff;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-white::before {
    background-color: #ffffff;
}

.timeline.timeline-6 .timeline-items .timeline-item:last-child {
    border-left-color: transparent;
    padding-bottom: 0;
}

.radio.radio-dark-60>span {
    background-color: #ECF0F3;
    border: 1px solid transparent;
}

.radio.radio-dark-60>input:focus~span {
    border: 1px solid transparent;
}

.radio.radio-dark-60>input:checked~span {
    background-color: #686871;
}

.radio.radio-dark-60>input:checked~span:after {
    background-color: #ffffff;
    border-color: #ffffff;
}

.radio.checkbox-light-dark-60>span {
    background-color: #E5EAEE;
    border: 1px solid transparent;
}

.radio.checkbox-light-dark-60>input:focus~span {
    border: 1px solid transparent;
}

.radio.checkbox-light-dark-60>input:checked~span {
    background-color: #686871 !important;
}

.radio.checkbox-light-dark-60>input:checked~span:after {
    border-color: #ffffff;
}

.radio.radio-outline.radio-dark-60>span {
    background-color: transparent;
    border-color: #D6D6E0;
}

.radio.radio-outline.radio-dark-60>input:focus~span {
    background-color: transparent;
    border-color: #D6D6E0;
}

.radio.radio-outline.radio-dark-60>input:checked~span {
    background-color: transparent;
    border-color: #686871;
}

.radio.radio-outline.radio-dark-60>input:checked~span:after {
    background-color: #686871;
    border-color: #686871;
}

.borderBottom {
    border-bottom: 1px dashed #ECF0F3;
}

.change-col-2 {
    @include down-screen($xs) {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}

.change-col-10 {
    @include down-screen($xs) {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
}

button#solicitar_cita {
    @media (max-width: ($xxs)) {
        min-height: 36px;
    }
}

.btn-light-danger-sweetalert2 {
    color: #F64E60 !important;
    background-color: #FFE2E5 !important;
    border-color: transparent !important;
}

button.swal2-confirm.btn-light-danger-sweetalert2.swal2-styled:hover {
    color: #ffffff !important;
    background-color: #F64E60 !important;
    border-color: transparent !important;
}

.btn-light-gray-sweetalert2 {
    color: #464E5F !important;
    background-color: #E5EAEE !important;
    border-color: #E5EAEE !important;
}

button.swal2-cancel.btn-light-gray-sweetalert2.swal2-styled:hover {
    color: #464E5F;
    background-color: #D6D6E0;
    border-color: #D6D6E0;
}

.btn-light-success-sweetalert2 {
    color: #388E3C !important;
    background-color: #E8F5E9 !important;
    border-color: #E8F5E9 !important;
}

button.swal2-confirm.btn-light-success-sweetalert2.swal2-styled:hover {
    color: #ffffff !important;
    background-color: #388E3C !important;
    border-color: #388E3C !important;
}

span#info-general,
span#env-correo,
span#ag-llamada,
span#historial,
a#seg-script {
    @media (max-width: 1280px) {
        margin-bottom: .5rem !important;
    }
}

div#nuevo {
    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:after {
        border-right: none;
        margin-right: 0px;
        border-left: none;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
        top: 0.5rem;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge>div {
        width: 8px;
        height: 8px;
    }
}

.icon-1rem {
    font-size: 1.05rem !important;
}

div#timeline-departamentos {
    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-media {
        width: 30px;
        height: 30px;
    }

    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
        top: 35px;
        left: 13px;
    }

    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
        height: calc(100% - 34px);
    }
}

div#amarrillo {
    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:after {
        border-right: none;
        margin-right: 0px;
        border-left: none;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
        top: 0.5rem;
        width: 40px;
        height: 40px;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge>div {
        width: 8px;
        height: 8px;
    }

    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item .timeline-badge {
        left: -15px;
    }

    &.timeline.timeline-4:after {
        bottom: 20px;
        top: 20px;
    }

    @media (max-width: 991.98px) {
        &.timeline.timeline-4:after {
            left: 14px !important;
        }
    }
}

.pl-30px {
    padding-left: 30px;
}

span#border-hover:hover {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.no-list {
    list-style-type: none !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    padding-inline-start: 0 !important;
}

.pt-1px {
    padding-top: 1px;
}

.pl-2px {
    padding-left: 2px;
}

.pr-2px {
    padding-right: 2px;
}

.pt-3px {
    padding-top: 3px;
}

.pr-1px {
    padding-right: 1px;
}

.pl-3px {
    padding-left: 3px;
}

.pt-2px {
    padding-top: 2px;
}

.pl-4px {
    padding-left: 4px;
}

.icon-1-26rem {
    font-size: 1.26rem;
}

.icon-1-2rem {
    font-size: 1.2rem;
}

.icon-1-26rem {
    font-size: 1.26rem
}

.icon-1-7rem {
    font-size: 1.7rem
}

.icon-1-39rem {
    font-size: 1.39rem;
}

#accordion-material {
    .card-title.text-dark-50.rounded-0:hover {
        color: #2171c1 !important;
        background-color: #F3F6F9 !important;

        i.text-dark-50 {
            color: #2171c1 !important;
        }
    }

    .card-title.text-primary.collapsed.rounded-0 {
        color: #2171c1 !important;
        background-color: #F3F6F9 !important;

        i.text-primary {
            color: #2171c1 !important;
        }
    }

    .card-label {
        font-size: 13px;
    }

    .card-title {
        padding: 7.75px 6.5px;
    }

    .card-body {
        padding: 7.75px 6.5px;
    }

    .svg-icon {
        margin-left: 5px;
    }
}

.pb-25rem {
    @include screen($xxl) {
        padding-bottom: 25rem;
    }

    @include down-screen($xl) {
        padding-bottom: 16rem;
    }

    @include down-screen($md) {
        padding-bottom: 10rem;
    }

    @include down-screen($sm) {
        padding-bottom: 9rem;
    }
}

.py-10 {
    @include screen($xxl) {
        padding-top: 6.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    @include down-screen($xl) {
        padding-top: 0.5rem !important;
        padding-bottom: 5.5rem !important;
    }
}

.widthSelect {
    @include screen($xxl) {
        width: 400px !important;
    }
}

.section-proyecto {
    position: relative;
    padding: 7.5rem 0;
    display: block;
    margin-right: -25px;
    margin-left: -25px;
}

.background-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
}

.background-holder.overlay-1:before {
    background: rgba(0, 0, 0, .55);
}

.background-holder.overlay:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

@media (min-width: 36em) {
    .fs-sm-6 {
        font-size: 5.61023rem !important;
    }
}

div#znav-container {
    &.znav-container:not([class*=sticky-]) {
        top: 0;
        left: 0;
        right: 0;
        z-index: 1030;
    }

    &.znav-freya {
        background-color: transparent;
    }
    &.znav-freya .container {
        background: -webkit-gradient(linear, left bottom, left top, color-stop(100%, rgba(255, 255, 255, .9)), color-stop(0, #fff));
        background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, .9) 100%, #fff 0);
        background: -o-linear-gradient(bottom, rgba(255, 255, 255, .9) 100%, #fff 0);
        background: linear-gradient(to top, rgba(255, 255, 255, .9) 100%, #fff 0);
        margin-top: 58px;
        // border-radius: 22px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
        box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
        padding-left: 33px;
        padding-right: 33px;
    }

    &.container {
        position: relative;
    }

    &.container {
        z-index: 1;
    }

    &.znav-container .navbar {
        font-weight: 600;
        font-size: .75019rem;
        letter-spacing: .01em;
        border-radius: 0;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding-left: 0;
        padding-right: 0;
        line-height: 1.5;
    }

    @media (min-width: 992px) {
        .navbar-expand-lg {
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
    }

    &.navbar {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: .5rem 1rem;
    }
}

.letter-spacing-1 {
    letter-spacing: 1px !important;
}

.letter-spacing-4px {
    letter-spacing: 4px !important;
}

.letter-spacing-0-4 {
    letter-spacing: 0.4px !important;
}

.letter-spacing-0-3 {
    letter-spacing: 0.3px !important;
}

// .z{
//     opacity: 1;
//     transform: matrix(1, 0, 0, 1, 0, 0);
// }
// .bg-image{
//     background-image: url(/header_1.jpg);
//     transform: translate3d(0px, -33px, 0px);
// }

button.modal-video-close-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-transform: none;
    transform: none;
    margin-right: 0;
    line-height: 0;
    justify-content: center;
    background: #333333;
    border-radius: 4.5px;
    border: 1px #747474 solid;
}

button.modal-video-close-btn:hover {
    background-color: #232323;
    border: 1px #747474 solid;
}

button.modal-video-close-btn::before {
    display: none;
}

button.modal-video-close-btn:after {
    font-family: Ki;
    line-height: 1;
    content: "" !important;
    font-size: 9px;
    color: #fff;
    transform: none !important;
    background-color: transparent !important;
    width: fit-content !important;
    height: 0px !important;
    top: 6px !important;
    border-radius: 0px !important;
    margin-top: 0px !important;
    left: 7px !important;
}

.modal-video-close-btn {
    top: -27px !important;
    right: -30px !important;
    width: 24px !important;
    height: 24px !important;
}

div#nav-tareas {
    @include down-screen($md) {
        display: flex !important;
        justify-content: center !important;
    }
}

.fc-view-harness-active {
    @include down-screen($md) {
        height: 100vh !important;
    }
}

.mt-175px {
    margin-top: 175px
}

.spinner.spinner-xl:before {
    width: 2.4rem;
    height: 2.4rem;
}

.w-95 {
    width: 95%;
}

.w-5 {
    width: 5%;
}

.w-30 {
    width: 30%;
}

.error-title {
    @include screen($xxl) {
        font-size: 100px !important;
        letter-spacing: 2px !important;
        margin-bottom: 3rem !important;
    }

    @include screen($md) {
        font-size: 72px;
        letter-spacing: 1px;
        margin-bottom: 3rem;
    }

    @include down-screen($md) {
        font-size: 35px;
        letter-spacing: 1px;
        margin-bottom: 2rem !important;
    }
}

.error-subtitle {
    @include screen($xxl) {
        font-size: 35px !important;
        letter-spacing: 1px !important;
        margin-bottom: 1.5rem !important;
    }

    @include screen($md) {
        font-size: 29px;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
    }

    @include down-screen($md) {
        font-size: 21px;
        letter-spacing: 1px;
        margin-bottom: 2rem !important;
    }
}

.error-subtitle-2 {
    @include screen($xxl) {
        font-size: 20px !important;
        letter-spacing: 1px !important;
        margin-bottom: 3rem !important;
    }

    @include screen($md) {
        font-size: 17px;
        letter-spacing: 1px;
        margin-bottom: 3rem;
    }

    @include down-screen($md) {
        font-size: 17px;
        letter-spacing: 1px;
        margin-bottom: 2rem !important;

    }
}

span.font-weight-bolder.text-hover-error:hover {
    color: #00ac9f !important;
}

.text-transform-none {
    text-transform: none !important;
}

.text-hover {
    &:hover {
        cursor: pointer;
    }
}

.min-width-100px {
    min-width: 100px;
}

.font-xxx-large {
    font-size: xxx-large;
}

.close-icon {
    right: 35px;
    top: -35px;
    z-index: 1;
}

.card-hover-shadow {
    transition: .3s;
}

.card-box-shadow {
    box-shadow: 0 6px 12px rgba(140, 152, 164, .075);
}

.card-hover-shadow:hover {
    box-shadow: 0 6px 12px rgba(140, 152, 164, .25) !important;
}

.card-sm>.card-body,
.card-sm>.collapse .card-body {
    padding: 1rem 1rem;
}

.tio-xl {
    font-size: 1.75rem;
}

.hs-unfold {
    position: relative;
    display: inline-block;
}

.font-size-17px {
    font-size: 17px !important;
}

.font-size-14px {
    font-size: 14px !important;
}

.font-size-13px {
    font-size: 13px !important;
}

.font-size-12px {
    font-size: 12px !important;
}

.font-size-11px {
    font-size: 11px !important;
}

.input-folder {
    background-color: #ffffff !important;
    border-bottom: 2px solid #E5EAEE !important;
    color: #464E5F !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding-bottom: 1px !important;
    text-align: center !important;
}

.list-group-item:last-child {
    border-top-width: 0;
}

.crm-proveedor {
    color: #B92545;
    font-weight: 500;
}

.line-height-0 {
    line-height: 0 !important;
}

.custom-item-red {
    .bar {
        fill: red !important;
    }
}

.top-20 {
    top: 20%;
}

.hidden-subtitle {
    @include down-screen($sm) {
        visibility: hidden
    }
}

.show-lista {
    visibility: hidden;

    @include down-screen($sm) {
        visibility: visible;
    }
}

.hidden-label {
    @include down-screen($sm) {
        display: none;
    }
}

.columns-2 {
    columns: 2 auto;
}

.dot-2 {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -3px;
    left: -5px;
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.navi .navi-item .navi-link.active .text-hover-warning {
    color: #ffa800;
    border-left: 3px solid #ffa800;
    padding-left: 10px;
}

.navi .navi-item .navi-link.active .text-hover-info {
    color: #8950FC;
    border-left: 3px solid #8950FC;
    padding-left: 10px;
}

.navi .navi-item .navi-link.active .text-hover-primary {
    color: #2171c1;
    border-left: 3px solid #2171c1;
    padding-left: 10px;
}

.navi.navi-active .navi-item .bg-navi-light-info.active {
    background-color: #f4efff;
    border-radius: 0;
}

.navi.navi-active .navi-item .bg-navi-light-primary.active {
    background-color: #ebf5ff;
    border-radius: 0;
}

.navi.navi-active .navi-item .bg-navi-light-warning.active {
    background-color: #fff7e8;
    border-radius: 0;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-4-5 {
    width: 5.5%;
}

.w-4 {
    width: 4%;
}

.w-8 {
    width: 8%;
}

.w-10 {
    width: 10%;
}

.w-12 {
    width: 12%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.aside-minimize {
    i.home-icon-menu {
        display: none;
    }
}
body::-webkit-scrollbar {
    width: .5rem;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(46, 46, 46, 0.3);
    background-color: #ECEEF6;
    border-radius: .25rem;
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: .25rem;

  }

.row.options-planos {
    padding: 0.75rem 0px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    // border-bottom: 1px dashed #ebedf3;
    // margin: 3px 0px;
    align-items: center;

    .w-eliminar {
        padding: 0px 5px;

        @include down-screen($xxl) {
            padding: 0px 11px;
            width: 6.3% !important;
        }

        @media screen and (max-width: ($xl)) {
            width: 9% !important;
        }

        @include down-screen($xxs) {
            width: 11% !important;
        }
    }

    .w-text {
        padding: 0px 5px;

        @media screen and (max-width: ($xxl)) {
            width: 82% !important;
        }

        @media screen and (max-width: ($xl)) {
            width: 74% !important;
        }

        @include down-screen($xxs) {
            width: 69% !important;
        }
    }

    .w-arrows {
        padding: 0px 5px;

        @media screen and (max-width: ($xxl)) {
            width: 11% !important;
        }

        @media screen and (max-width: ($xl)) {
            width: 17% !important;
        }

        @include down-screen($xxs) {
            width: 20% !important;
        }
    }

    .nom-plano {
        padding: 0px 5px;

        @media screen and (max-width: ($xxl)) {
            width: 89% !important;
        }

        @media screen and (max-width: ($xl)) {
            width: 83% !important;
        }

        @include down-screen($xxs) {
            width: 80% !important;
        }
    }
}

.text-align-last-justify {
    text-align-last: justify;
}

.btn.btn-text-turquesa {
    color: #0097A7;
}

.btn.btn-text-turquesa i {
    color: #0097A7;
}

.btn.btn-text-turquesa .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #0097A7;
}

.btn.btn-text-turquesa .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-text-turquesa.dropdown-toggle:after {
    color: #0097A7;
}

.btn.btn-text-turquesa.disabled,
.btn.btn-text-turquesa:disabled {
    color: #0097A7;
}

.btn.btn-text-turquesa.disabled i,
.btn.btn-text-turquesa:disabled i {
    color: #0097A7;
}

.btn.btn-text-turquesa.disabled .svg-icon svg g [fill],
.btn.btn-text-turquesa:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #0097A7;
}

.btn.btn-text-turquesa.disabled .svg-icon svg:hover g [fill],
.btn.btn-text-turquesa:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-text-turquesa.disabled.dropdown-toggle:after,
.btn.btn-text-turquesa:disabled.dropdown-toggle:after {
    color: #0097A7;
}

.btn.btn-hover-turquesa:hover:not(.btn-text),
.btn.btn-hover-turquesa:focus:not(.btn-text),
.btn.btn-hover-turquesa.focus:not(.btn-text) {
    color: #ffffff !important;
    background-color: #0097A7 !important;
    border-color: #0097A7 !important;
}

.btn.btn-hover-turquesa:hover:not(.btn-text) i,
.btn.btn-hover-turquesa:focus:not(.btn-text) i,
.btn.btn-hover-turquesa.focus:not(.btn-text) i {
    color: #ffffff !important;
}

.btn.btn-hover-turquesa:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-turquesa:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-turquesa.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff !important;
}

.btn.btn-hover-turquesa:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-turquesa:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-turquesa.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-turquesa:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-turquesa:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-turquesa.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff !important;
}

.btn.btn-hover-turquesa:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-turquesa:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-turquesa.dropdown-toggle,
.show .btn.btn-hover-turquesa.btn-dropdown {
    color: #ffffff !important;
    background-color: #0097A7 !important;
    border-color: #0097A7 !important;
}

.btn.btn-hover-turquesa:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-turquesa:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-turquesa.dropdown-toggle i,
.show .btn.btn-hover-turquesa.btn-dropdown i {
    color: #ffffff !important;
}

.btn.btn-hover-turquesa:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-turquesa:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-turquesa.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-turquesa.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff !important;
}

.btn.btn-hover-turquesa:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-turquesa:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-turquesa.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-turquesa.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-turquesa:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-turquesa:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-turquesa.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-turquesa.btn-dropdown.dropdown-toggle:after {
    color: #ffffff !important;
}

.btn.btn-hover-turquesa.btn-clean {
    border: 0 !important;
}

.xml-view {
    text-align: justify;
    font-size: 12px;
    overflow-x: auto;
    text-align-last: start !important;
    border: 6px solid #ECF0F3;
    border-bottom: 0px;
    padding: 0.6rem;
}

.text-align-last-center {
    text-align-last: center;
}

.empresa-parrilla {
    font-weight: 600;
    text-align: center;
    border-bottom: 0px !important;
}

#parrilla th:last-child,
#parrilla th:first-child,
#parrilla td:last-child,
#parrilla td:first-child {
    border-left: none;
    border-right: none;
}

#parrilla tr:last-child td {
    border-bottom: none;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-flex-end {
    justify-content: flex-end !important;
}

.width-content-validation {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

#checador {

    table,
    th,
    td {
        border: 1px solid #ecf0f3;
    }

    tbody tr:hover {
        background-color: #ebeeef !important;
    }

    tr#fechas {
        text-align: center;

        & th.w-4-5 {
            color: #464E5F;
            border-bottom: none;
        }

        th:nth-last-child(3) {
            border-right: 1px solid #E8F5E9;
        }

        th:nth-last-child(3) {
            border-right: 1px solid #E8F5E9;
        }
    }

    tr#dias {
        text-align: center;
        background-color: #00cccc;

        & th.w-4-5 {
            border: none;
            color: white;
        }

        th:nth-last-child(1) {
            border-right: 1px solid #E8F5E9;
        }
    }

    th#he {
        border: 1px solid #FFA500;
        background-color: #FFA500;
        color: white !important;
        font-weight: 600;
    }

    th#tl {
        border: 1px solid #1558D4;
        background-color: #1558D4;
        color: white !important;
        font-weight: 600;
    }

    td#cantidad-he {
        // border:1px solid #E8F5E9;
        font-weight: 600;
    }

    td#cantidad-th {
        // border:1px solid #E1F0FF;
        font-weight: 600;
    }

    td:nth-last-child(3) {
        border-right: 1px solid #E8F5E9;
    }

    tr.text-center:nth-child(2n+0) {
        background-color: #f3f6f9f5;
    }

    tr:last-child {
        td:not(:first-child) {
            border-bottom: 2px solid #00cccc;
        }
    }
}

.text-red {
    color: #C62828;

}

.empleado {
    color: #70707d;
    font-size: .9rem;
    letter-spacing: .1rem;
    font-weight: 600;
}

.word-spacing-1vw {
    word-spacing: 1vw;
}

.top-26 {
    top: 26%
}

.border-radius-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.border-radius-4px {
    border-radius: 4px;
}

.border-radius-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.text-cyan {
    color: #07A3CF !important;
}

.text-morado {
    color: #790FD5 !important;
}

.text-naranja {
    color: #FF7400 !important;
}

#tcalendar_p_info tr:nth-child(2n+0) {
    background-color: #f3f6f9f5;
}

.tcalendar_p_info tr:nth-child(2n+0) {
    background-color: #f3f6f9f5;
}

#mi-proyecto tr:last-child {
    border-bottom: none;
}

.border-top-2px {
    border-top: 2px solid #e3e6e8
}

#calendario-proyectos td:first-child {
    border-left: none;
}

.text-align-last-left {
    text-align-last: left;
}

.swal2-html-container {
    .select-search {
        &.has-focus {
            .select-search {
                &__select {
                    height: 150px;
                    max-height: 150px;
                }

                &__value {
                    height: 200px;
                }
            }
        }
    }
}

.swal2-html-container.overflow-hidden.htmlClass {
    .select-search {
        &.has-focus {
            .select-search {
                &__select {
                    height: auto;
                    max-height: 150px;
                }

                &__value {
                    height: auto;
                }
            }
        }
    }
}

.setListTable {
    display: table;
    margin: 0 auto;
    line-height: 18px;
    font-size: 11px;
}

.center-td {
    display: table;
    margin: 0 auto;
}

.w-33 {
    width: 33%;
}

.tagify {
    &__tag {
        &__newtable {
            border-radius: 0px !important;

            span {
                font-size: 11px;
            }
        }
    }
}

.swal2-html-container {
    .show.dropdown {
        height: 165px;

        & .p-0.dropdown-menu.show {
            margin-top: 11px !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%) !important;
        }
    }
}

.border-radius-3px {
    border-radius: 3px !important;
}

.white-space-pre-wrap {
    white-space: pre-wrap !important;
}

.white-space-pre-line {
    white-space: pre-line !important;

}

.min-width-180px {
    min-width: 180px;
}

.min-width-153px {
    min-width: 153px;
}

.w-max-content {
    width: max-content;
}

.circle-swal {
    flex-flow: column !important;
}

.bg-comment {
    border-radius: 0.5rem;
    position: relative;
    background-color: #F3F6F9;
    margin: 0px 30px;
    padding: 0.75rem 1.5rem !important;
}

.border-bottom-2px-info-hover:hover {
    border-bottom: 2px solid #8950FC;
}

.swal2-icon.swal2-icon-show {
    border: none;
}

.text-transform-none {
    text-transform: none !important;
}

@media (max-width: 991.98px) {
    span.ocultar-checador {
        display: none;
    }
}

.text-area-white {
    background-color: white !important;
    outline: none !important;
    border: none !important;
    display: block !important;
    width: 100% !important;
    font-size: 13px !important;
    color: #9d9da5 !important;
    padding: 15px 0px 0px 0px !important;

    &::-webkit-input-placeholder {
        color: #9d9da5 !important;
    }
}

input[type="file"]#file-upload,
#datosGenerales,
#recibosNomina,
#altasBajas,
#factura,
#presupuesto,
#pago,
#avance,
#adjunto-contrato,
#adjunto,
#adjunto-carta,
#contrato,
#carta,
.avance,
.file-input,
#adjunto_evidencia {
    display: none !important;
}

.tagify {
    .tagify__tag {
        div {
            order: 1;
            padding-right: 0rem;
        }
    }
}

div#tag-tagify {
    .tagify .tagify__tag .tagify__tag__removeBtn:after {
        color: white !important;
    }
}

.p-2-5px {
    padding: 2.5px !important;
}

.mt-2px {
    margin-top: 1px !important;
}

.d-contents {
    display: contents !important;
}

.line-break-anywhere {
    line-break: anywhere;
}

.timeline-content {
    min-height: 50px;
}

div#task {
    @include down-screen($sm) {
        div#card-header-task {
            text-align: center;
            justify-content: center;
        }

        div#d-flex-task {
            place-content: stretch flex-end;
            flex-flow: column-reverse nowrap;
            margin-bottom: 5px;
            line-height: 18px;
        }

        span#showListReturn {
            position: absolute;
            left: 0;
            top: 0;
        }

        span#btn-return {
            position: fixed;
        }

        .center-mobile {
            justify-content: center;
        }

        .margin-mobile {
            margin: 0 !important;
        }
    }
}

.w-15 {
    width: 15%;
}

.w-85 {
    width: 85%;
}

.w-20 {
    width: 20%;
}

.h-80 {
    height: 80% !important;
}

.h-20 {
    height: 20% !important;
}

.h-15 {
    height: 15% !important;
}

.py-9px {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.font-size-5px {
    font-size: 5px !important;
}

.switch-notify {

    .switch.switch-sm input:empty~span:before,
    .switch.switch-sm input:empty~span:after {
        border-radius: 5px !important;
    }

    .switch.switch-sm input:empty~span:after {
        height: 16px !important;
        width: 21px !important;
        top: 1px !important;
    }

    label {
        margin-bottom: 0px !important;
    }

    .switch.switch-sm input:empty~span {
        margin: 0px !important;
        height: 18px !important;
        width: 38px !important;
    }

    .switch.switch-outline.switch-info input:checked~span:after {
        color: #ffffff;
        background-color: #8950FC;
    }

    .switch.switch-outline.switch-info input:checked~span:before {
        border: 1.8px solid #8950FC;
        background-color: transparent;
    }

    .switch.switch-sm input:checked~span:after {
        margin-left: 17px;
    }
}

.text-transform-none {
    text-transform: none !important;
}

div#responsable-notify {
    .tagify .tagify__tag .tagify__tag__removeBtn:after {
        color: $color-text !important;
    }
}

.text-color-8080 {
    color: $color-text;
}

div#symbol-total-contactos {
    @include down-screen($md) {
        text-align: center;
        margin-top: 11px;

        span.ml-4 {
            margin-left: 0px !important;
            display: block;
            margin-top: 8px;
        }
    }
}

.svg-color-nofify {

    path,
    rect {
        fill: #8950FC;
    }
}

.svg-color-nofify.disable-bg {

    path,
    rect {
        fill: $color-text;
    }
}

.text-center-webkit {
    text-align: -webkit-center;
}

.min-width-136px {
    min-width: 136px;
}

.place-content-center {
    place-content: center;
}

.w-65 {
    width: 65%;
}

.pointer-events-none {
    pointer-events: none;
    opacity: 0.9;
}

.w-fit-content {
    width: fit-content;
}

.border-rh-info {
    border-top: 2px solid #f3f6f9;
    padding: 1.75rem 1.5rem;
    border-top-left-radius: .42rem;
    border-top-right-radius: .42rem;
    padding-top: 46px;
}

.top-12px {
    top: 12px
}

.ribbon-rh {
    top: -2px;
    left: 1px;
}

.min-width-180px {
    min-width: 180px;
}

table#calendario-proyectos::-webkit-scrollbar {
    width: 8px;
    /* Tamaño del scroll en vertical */
    height: 8px;
    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
table#calendario-proyectos::-webkit-scrollbar-thumb {
    background-color: #c2c2c2e8;
    border-radius: 6px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
table#calendario-proyectos::-webkit-scrollbar-thumb:hover {
    background-color: #c2c2c2;
    // opacity: 0.9;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
table#calendario-proyectos::-webkit-scrollbar-thumb:active {
    background-color: #c2c2c2;
    // opacity: 0.9;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
table#calendario-proyectos::-webkit-scrollbar-track {
    background: #F3F6F9;
    // border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
table#calendario-proyectos::-webkit-scrollbar-track:hover,
table#calendario-proyectos::-webkit-scrollbar-track:active {
    background: #F3F6F9;
}

table#calendario-proyectos tr:last-child td {
    border-bottom: 0;
}

.border-radius-10px {
    border-radius: 10px;
}

.dot-calendario {
    height: 7px !important;
    width: 7px !important;
    border-radius: 50%;
    margin-right: 10px !important;
    display: inline-flex;
}

.py-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.label-fase {
    color: white;
    border: transparent;
    padding: 0.3rem 0.6rem;
    width: auto;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.42rem;
    margin-right: 0.5rem;
}

@media (min-width:992px) {

    div,
    ol,
    pre,
    span,
    ul {
        scrollbar-width: thin;
        scrollbar-color: #eff2f5 transparent
    }

    div::-webkit-scrollbar,
    ol::-webkit-scrollbar,
    pre::-webkit-scrollbar,
    span::-webkit-scrollbar,
    ul::-webkit-scrollbar {
        width: .4rem;
        height: .4rem
    }

    div::-webkit-scrollbar-thumb,
    ol::-webkit-scrollbar-thumb,
    pre::-webkit-scrollbar-thumb,
    span::-webkit-scrollbar-thumb,
    ul::-webkit-scrollbar-thumb {
        background-color: #eff2f5
    }

    div:hover,
    ol:hover,
    pre:hover,
    span:hover,
    ul:hover {
        scrollbar-color: #e9edf1 transparent
    }

    div:hover::-webkit-scrollbar-thumb,
    ol:hover::-webkit-scrollbar-thumb,
    pre:hover::-webkit-scrollbar-thumb,
    span:hover::-webkit-scrollbar-thumb,
    ul:hover::-webkit-scrollbar-thumb {
        background-color: #e9edf1
    }
}

// .scroll {
//     overflow: scroll;
//     position: relative
// }

@media (max-width:991.98px) {
    .scroll {
        overflow: auto
    }
}

.scroll-x {
    overflow-x: scroll;
    position: relative
}

@media (max-width:991.98px) {
    .scroll-x {
        overflow-x: auto
    }
}

.scroll-y {
    overflow-y: scroll;
    position: relative
}

@media (max-width:991.98px) {
    .scroll-y {
        overflow-y: auto
    }
}

.hover-scroll {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll {
        overflow: hidden;
        border-right: .4rem solid transparent;
        border-bottom: .4rem solid transparent;
        margin-right: -.4rem;
        margin-bottom: -.4rem
    }

    .hover-scroll:hover {
        overflow: scroll;
        border-right: 0;
        border-bottom: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll {
            overflow: scroll;
            position: relative;
            border-right: 0;
            border-bottom: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll {
        overflow: auto
    }
}

.hover-scroll-y {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll-y {
        overflow-y: hidden;
        border-right: .4rem solid transparent;
        margin-right: -.4rem
    }

    .hover-scroll-y:hover {
        overflow-y: scroll;
        border-right: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll-y {
            overflow-y: scroll;
            position: relative;
            border-right: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll-y {
        overflow-y: auto
    }
}

.hover-scroll-x {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll-x {
        overflow-x: hidden;
        border-bottom: .4rem solid transparent
    }

    .hover-scroll-x:hover {
        overflow-x: scroll;
        border-bottom: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll-x {
            overflow-x: scroll;
            position: relative;
            border-bottom: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll-x {
        overflow-x: auto
    }
}

.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.25rem
}

.hover-scroll-overlay-y::-webkit-scrollbar {
    width: calc(.4rem + var(--scrollbar-space))
}

.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border-right: var(--scrollbar-space) solid transparent
}

.hover-scroll-overlay-y:hover {
    overflow-y: overlay
}

@-moz-document url-prefix() {
    .hover-scroll-overlay-y {
        overflow-y: scroll;
        position: relative
    }
}

.border-radius-21px {
    border-radius: 21px !important;
}

.flex-stack {
    justify-content: space-between;
    align-items: center
}

.w-94 {
    width: 94%;
}

.align-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flex-direction-column {
    flex-direction: column;
}

.border-dashed {
    border-style: dashed !important;
    border-color: #e4e6ef !important;
    border-radius: .475rem !important;
    border: 1px #eff2f5;
}

.mw-lg-75px {
    max-width: 75px !important
}

.mw-50px {
    max-width: 50px !important
}

.border-gray-300 {
    border-color: #e4e6ef !important
}

.rounded-avatar {
    border-radius: 50% !important;
}

.color-instalacion {
    color: #17a2b8
}

.color-mantenimiento {
    color: #2756C3
}

.color-mantenimiento-preventivo {
    color: #eea71a
}

.xehagon {
    clip-path: polygon(45% 1.3397459622%, 46.5797985667% .6030737921%, 48.2635182233% .1519224699%, 50% 0, 51.7364817767% .1519224699%, 53.4202014333% .6030737921%, 55% 1.3397459622%, 89.6410161514% 21.3397459622%, 91.0688922482% 22.3395555688%, 92.3014605826% 23.5721239031%, 93.3012701892% 25%, 94.0379423592% 26.5797985667%, 94.4890936815% 28.2635182233%, 94.6410161514% 30%, 94.6410161514% 70%, 94.4890936815% 71.7364817767%, 94.0379423592% 73.4202014333%, 93.3012701892% 75%, 92.3014605826% 76.4278760969%, 91.0688922482% 77.6604444312%, 89.6410161514% 78.6602540378%, 55% 98.6602540378%, 53.4202014333% 99.3969262079%, 51.7364817767% 99.8480775301%, 50% 100%, 48.2635182233% 99.8480775301%, 46.5797985667% 99.3969262079%, 45% 98.6602540378%, 10.3589838486% 78.6602540378%, 8.9311077518% 77.6604444312%, 7.6985394174% 76.4278760969%, 6.6987298108% 75%, 5.9620576408% 73.4202014333%, 5.5109063185% 71.7364817767%, 5.3589838486% 70%, 5.3589838486% 30%, 5.5109063185% 28.2635182233%, 5.9620576408% 26.5797985667%, 6.6987298108% 25%, 7.6985394174% 23.5721239031%, 8.9311077518% 22.3395555688%, 10.3589838486% 21.3397459622%)
}

.octagon {
    clip-path: polygon(46.1731656763% .7612046749%, 47.411809549% .3407417371%, 48.6947380778% .0855513863%, 50% 0, 51.3052619222% .0855513863%, 52.588190451% .3407417371%, 53.8268343237% .7612046749%, 82.1111055711% 12.4769334274%, 83.2842712475% 13.0554747147%, 84.3718855375% 13.7821953496%, 85.3553390593% 14.6446609407%, 86.2178046504% 15.6281144625%, 86.9445252853% 16.7157287525%, 87.5230665726% 17.8888944289%, 99.2387953251% 46.1731656763%, 99.6592582629% 47.411809549%, 99.9144486137% 48.6947380778%, 100% 50%, 99.9144486137% 51.3052619222%, 99.6592582629% 52.588190451%, 99.2387953251% 53.8268343237%, 87.5230665726% 82.1111055711%, 86.9445252853% 83.2842712475%, 86.2178046504% 84.3718855375%, 85.3553390593% 85.3553390593%, 84.3718855375% 86.2178046504%, 83.2842712475% 86.9445252853%, 82.1111055711% 87.5230665726%, 53.8268343237% 99.2387953251%, 52.588190451% 99.6592582629%, 51.3052619222% 99.9144486137%, 50% 100%, 48.6947380778% 99.9144486137%, 47.411809549% 99.6592582629%, 46.1731656763% 99.2387953251%, 17.8888944289% 87.5230665726%, 16.7157287525% 86.9445252853%, 15.6281144625% 86.2178046504%, 14.6446609407% 85.3553390593%, 13.7821953496% 84.3718855375%, 13.0554747147% 83.2842712475%, 12.4769334274% 82.1111055711%, .7612046749% 53.8268343237%, .3407417371% 52.588190451%, .0855513863% 51.3052619222%, 0 50%, .0855513863% 48.6947380778%, .3407417371% 47.411809549%, .7612046749% 46.1731656763%, 12.4769334274% 17.8888944289%, 13.0554747147% 16.7157287525%, 13.7821953496% 15.6281144625%, 14.6446609407% 14.6446609407%, 15.6281144625% 13.7821953496%, 16.7157287525% 13.0554747147%, 17.8888944289% 12.4769334274%)
}

.content-bg-for-font-color {
    color: #EEF0F8
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.letter-spacing-1px {
    letter-spacing: 1px;
}

.nav-center {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    min-width: auto;
    max-width: fit-content;
    margin: auto;
}

.font-size-2-1rem {
    font-size: 2.1rem
}

.width-inherit {
    width: inherit;
}

.img-responsive {
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}

.timeline.timeline-historial {
    position: relative;
}

.timeline.timeline-historial:before {
    content: '';
    position: absolute;
    left: 99px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #ECF0F3;
}

.timeline.timeline-historial .timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 1.5rem;
}

.timeline.timeline-historial .timeline-item:last-child {
    margin-bottom: 0;
}

.timeline.timeline-historial .timeline-item .timeline-label {
    width: 80px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 1rem !important;
    font-weight: 500;
    position: relative;
    color: #464E5F;
    text-align: right;
}

.timeline.timeline-historial .timeline-item .timeline-badge {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-left: 11px;
}

.timeline.timeline-historial .timeline-item .timeline-badge span {
    display: block;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: #ECF0F3;
}

.timeline.timeline-historial .timeline-item .timeline-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 11px;
}

.unidad-tickets {
    .select-search.has-focus {
        .select-search__option {
            font-size: 12px !important;
            text-align: center;
        }
    }
}

.drop-files {
    background-color: #f1faff;
    border-style: dashed;
    border-radius: .475rem;
    border-color: #009ef7;
    border-width: 1px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.box-shadow-36 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.box-shadow-53 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.box-shadow-34 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.notify-p-stiky {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.tool-card-motivo {
    .tool-titulo {
        background-color: #ECEFF1;
        padding: 0.5rem;
    }

    .tool-contenido {
        span {
            color: #5F6A6A;
            font-weight: bold;
        }
    }

    .tooltip-inner {
        min-width: 20rem;
        width: auto;
        padding: 0;
    }
}

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;

    &:before {
        content: '';
        background: linear-gradient(to right, transparent, #2171c1, transparent);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        letter-spacing: 0.5px;
    }

    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        padding: 0 .5em;
        line-height: 1.5em;
        font-weight: 600;
        color: #2171c1;
        background-color: #fcfcfa;
    }

    &__warning {
        &:before {
            background: linear-gradient(to right, transparent, #FFA800, transparent);
        }

        &:after {
            color: #FFA800;
        }
    }

    &__primary2 {
        &:before {
            background: linear-gradient(to right, transparent, #007af0, transparent);
        }

        &:after {
            color: #007af0;
        }
    }
}

// Se agregó estilo para calendarios, flechas y botón alinados a la derecha
.fc-toolbar-chunk:nth-of-type(3) {
    width: -webkit-fill-available;
    text-align: end;
}

.min-w-fit-content {
    min-width: fit-content;
}

.tool-time-line {
    .tool-titulo {
        padding: 0;
    }

    .tooltip-inner {
        padding: 0;
    }
}

.disable-presupuesto:disabled {
    background-color: #fee9ec !important;
    border-color: #fee9ec !important;
    opacity: 1 !important;
    color: #464e5f !important;
    font-weight: 400 !important;
}

.disable-concepto {
    background-color: #f3f6f9 !important;
    border-color: #f3f6f9 !important;
    opacity: 0.8 !important;
}

.vicio_oculto-presupuesto {
    background-color: #e7f1e8 !important;
    border-color: #e7f1e8 !important;
    opacity: 1 !important;
    color: #464e5f !important;
    font-weight: 400 !important;
}

.text-hover-ticket {
    color: #464E5F;

    &:hover {
        color: #9E9D24;
        text-decoration: underline;
        text-decoration-color: #9E9D24;
    }
}

.text-hover-obra:hover {
    color: #EF6C00;
    text-decoration: underline;
    text-decoration-color: #EF6C00;
}

.border-hover-light:hover {
    border-color: #e4e6ef !important
}

.text-fase1 {
    color: #008F8F;
}

.text-fase2 {
    color: #EF6C00;
}

.text-fase3 {
    color: #9E9D24;
}

.table-sol-fact {
    thead {
        font-weight: 600;
        color: #707cbb;
        font-size: 1rem;
        border-bottom: 1.5px solid #707cbb;
    }

    tr:nth-child(2n) {
        border-top: 1.1px solid #c5cae9;
        border-bottom: 1.1px solid #c5cae9;
    }

    tr:last-child {
        border-bottom: none;
    }

    .text-solicitud {
        color: #7986CB;
        font-weight: 600;
    }

    .bg-header {
        background: #f6f6fb;
    }

    tr.font-weight-light:hover {
        background: #F3F6F9;
    }
}

.btn.btn-text-solicitud {
    color: #7986CB;
}

.btn.btn-text-solicitud i {
    color: #7986CB;
}

.btn-pending-payment-sweetalert2 {
    color: #ffffff !important;
    background-color: #6795db !important;
    border-color: #6795db !important;
    padding: 6px 10px;
    font-size: 1rem !important;
}

.text-underline-position {
    text-underline-position: under;
}

.place-items-center {
    place-items: center;
}

.swal2-title {
    text-transform: uppercase;
}

td.descripcion {
    @include down-screen($md) {
        min-width: 100vw;
    }
}

.w-40rem {
    width: 40rem
}

.label-status {
    display: inline-flex;
    padding: 0.4rem 0.5rem;
    font-size: .65rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    border: transparent;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.475rem;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.3px;
}

.delete-confirm {
    background-color: #e34646 !important;
}

.btn_custom-alert {
    font-size: 1rem !important;
    padding: .625em 1.1em;
}

.btn-cancel-alert {
    font-size: 1rem !important;
    background-color: #e3e5e7 !important;
    color: #616161 !important;
    padding: .625em 1.1em;
}

.overflow-visible {
    overflow: visible !important;
}

.w-18 {
    width: 18%;
}



.identificador-aceptado {
    color: #824cb1 !important;
    font-weight: 700;
    border-bottom: 1px solid #824cb1;
}

.icons-sombra {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icons-sombra .icon-div {
    line-height: 1.3;
    position: relative;
    z-index: 0;
}

.icons-sombra .icon-div:after {
    position: absolute;
    top: -5px;
    left: 7px;
    content: '';
    width: 40px;
    height: 40px;
    background: #ebecff;
    z-index: -1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.icons-sombra .icon-div i {
    font-size: 35px;
    color: #6b76ff;
    background: linear-gradient(45deg, #6b75ff 0%, #6b75ff 0%, #59b7ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icons-sombra .title-icon {
    font-size: 1.08rem;
    font-weight: 600;

}

.min-width-608px {
    min-width: 608px;
}

.table-layout-fixed {
    table-layout: fixed;
}

.w-70 {
    width: 70%;
}

.form-control-sm.bg-light-primary2:focus {
    background: #ecf8ff;
}

.visibility-hidden {
    visibility: hidden;
}

.table-utilidad {
    table-layout: fixed;

    thead {
        tr {
            text-align: center;
        }
    }

    tbody {
        text-align: center;
    }
}

.card-title-utilidad {
    font-weight: 700;
    color: #202f8a;
    font-size: 1.5rem;
    letter-spacing: 0.4px;
}

.utilidad-head-table {
    background: #eceefa;
    color: #213c95;
    height: 36px;
    letter-spacing: 0.2px;
    border-radius: 3px;
}

.utilidad-table-title {
    display: flex;
    align-items: center;
    font-size: 1.05rem;
    text-align: center;
    font-weight: 400;
    color: #000;
    justify-content: center;
}

.div-general-utilidad {
    display: -webkit-inline-box;
}

.tr-utilidad-table {
    height: 40px;
    color: #626262;
    font-weight: 300;
}

.border-utilidad:first-child {
    border-top: none !important;
}

.border-utilidad:nth-last-child(2n) {
    border-top: 1.2px solid #eceefa;
    border-bottom: 1.2px solid #eceefa;
    background: #f9fafd;
}

.div-general-utilidad:hover {
    box-shadow: 0px 0px 14px 3px rgba(33, 60, 149, 0.3);
    border: none;

    .utilidad-table-title {
        background: #213c95;
        color: white;
        font-weight: 500;
        opacity: 0.95;
    }

    .hover-utilidad-table {
        background: #f6f7fd;
    }

    .tr-utilidad-table {
        font-weight: 500;
        color: #213c95;
    }

    .link-utilidad {
        border-bottom: 1px solid #213c95;
        color: #213c95;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            color: #996c4a;
            border-bottom: 1px solid #996c4a;
        }
    }

    .tr-utilidad-table {
        border-top: 1px dashed #d6dbee;
    }

    .tr-utilidad-table:first-child {
        border-top: none !important;
    }
}

.btn.btn-utilidad {
    color: white;
    border-color: #213c95;
    background-color: #213c95;
    font-weight: 500;
    letter-spacing: 0.5px;

    .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: white;
    }

    i {
        color: white;
    }
}

.btn.btn-utilidad:hover {
    background-color: #1d3481;
}

.btn.btn-light-utilidad {
    color: #213c95;
    border-color: #eceefa;
    background-color: #eceefa;
    font-weight: 500;
    letter-spacing: 0.5px;

    .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: #213c95;
    }

    i {
        color: #213c95;
    }
}

.text-table-utilidad {
    color: #213c95;
}

.text-utilidad-table:hover {
    color: #213c95;
    text-decoration-line: underline;
    text-decoration-color: #213c95;
    cursor: pointer;

}

.btn.btn-hover-utilidad:hover {
    color: white;
    border-color: #213c95;
    background-color: #213c95;

    .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: white;
    }

    i {
        color: white;
    }
}

.bg-input-disable-danger {
    .input-group.input-group-solid {
        background-color: #FFE2E5 !important;
    }

    .input-group-prepend {
        i {
            color: #F64E60 !important;
        }
    }

    .disable-danger,
    .col-form-label {
        color: #F64E60 !important;
    }
}

.bg-input-disable-success {
    .input-group.input-group-solid {
        background-color: #E8F5E9 !important;
    }

    .input-group-prepend {
        i {
            color: #388E3C !important;
        }
    }

    .disable-success,
    .col-form-label {
        color: #388E3C !important;
    }
}

.btn-cancel-alert {
    font-size: 1rem !important;
    background-color: #e3e5e7 !important;
    color: #616161 !important;
    padding: .625em 1.1em;
}

.btn_custom-alert {
    font-size: 1rem !important;
    padding: .625em 1.1em;
}

.btn-renovar-lic-confirm {
    background-color: #020293 !important;
}

.btn-prestacion-confirm {
    background-color: #319d95 !important;
}

.btn-pending-payment-confirm {
    background-color: #577eb9 !important;
}

.historial-vacaciones {
    img {
        border-radius: 4px;
        width: 4rem;
    }
}

.transition-prestaciones {
    transition: all 1s linear;
    display: block;
}

.border-bottom-dashed {
    border-bottom: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-style: dashed;
}

.bg-calendar-1 {
    background-image: linear-gradient(to right bottom, #72a8de, #5b9bdf, #438ee0, #2980df, #0372de);
}

.bg-calendar-2 {
    background-image: linear-gradient(to right bottom, #81c784, #6fb972, #5daa60, #4b9c4e, #388e3c);
}

.z-index-1 {
    z-index: 1 !important
}

.mt-n-100px {
    margin-top: -100px;
}

.bg-primary2 {
    background-color: #009ef7 !important
}

.table_calendar {
    border-collapse: collapse;
    border-spacing: 0;
    background: #fff;
    border-radius: 0 0 1em 1em;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, .2), 0 3px 1px #fff;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .2), 0 3px 1px #fff;
    color: #555;
    display: inline-block;
    padding: 1em;

    tbody td {
        padding: 0;
        padding: .5em 1em;
        text-align: center;
        font-size: 1rem;
        font-weight: 300;

        &:hover {
            background: #cacaca;
            color: #fff;
            font-weight: 600;
            border-radius: 1.333em;
            cursor: pointer;
        }
    }

    .current-day {
        background: #e66b6b;
        color: white;
        font-weight: 600;
        border-radius: 1.333em;
        cursor: pointer;
    }
}

.h-10px {
    height: 10px;
}


.dropdown-crm {
    .btn.btn-primary.dropdown-toggle {
        background-color: #2171c1;
        border-color: #2171c1;
        color: white;
        justify-content: end;
        font-weight: 600;
        letter-spacing: 0.2px;

        &::after {
            margin-left: 0;
            font-weight: 900;
            color: white !important;
        }

        &:active {
            background-color: #156ac1 !important;
            border-color: transparent !important;

            &::after {
                color: white !important;
            }
        }

        &:hover {
            background-color: #156ac1;
            border-color: transparent;

            &::after {
                color: white;
            }
        }
    }

    &.show.dropdown {
        .dropdown-menu {
            padding: 0;

            .generar-evento,
            .actualizar-evento,
            .guardar,
            .generar-pdf {
                font-weight: 500;
                color: #7f7f7f;

            }

            .generar-evento {
                &:hover,
                &.dropdown-item.active {
                    background-color: #EEE5FF;
                    color: #8950FC;
                    i {
                        color: #8950FC;
                    }
                }
            }

            .actualizar-evento 
                &:hover,
                &.dropdown-item.active {
                    background-color: #FFF4DE;
                    color: #FFA800;
                    i {
                        color: #FFA800;
                    }
                }
            }

            .guardar {
                &:hover,
                &.dropdown-item.active {
                    background-color: #E8F5E9;
                    color: #388E3C;
                    i {
                        color: #388E3C;
                    }
                }
            }
            .generar-pdf {
                &:hover,
                &.dropdown-item.active {
                    background-color: #E1F0FF;
                    color: #2171c1;

                    i {
                        color: #2171c1;
                    }
                }
            }
        }
    }


.border-top-4px-info {
    border-top-color: #8950fc !important;
    border-top-width: 4px !important;
    border-top-style: solid;
}

.icons-cuadro .icon-div {
    line-height: 1.3;
    position: relative;
    z-index: 0;
}

.icons-cuadro .icon-div:after {
    position: absolute;
    top: -5px;
    left: 14px;
    content: '';
    width: 45px;
    height: 45px;
    background: #f1e9ff;
    z-index: -1;
    -webkit-border-radius: 15%;
    -moz-border-radius: 15%;
    -ms-border-radius: 15%;
    border-radius: 15%;
}

.icons-cuadro .icon-div i {
    font-size: 45px;
    color: #8950FC;
}