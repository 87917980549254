.satisfaccion{
    background-color: white;
}

.satisfaccion_tittle{
    font-size: 3rem;
    font-weight: 600;
    color: #000;
    margin: 2rem 0; 
    text-align: center; 
}

.satisfaccion_container{
    padding: 2rem;
}

.satisfaccion_container div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.SelectFiltrosContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.SelectFiltrosContainer select{
    height: 2rem;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.satisfaccion_select div{
    margin-left: 1rem;
}

.satisfaccion_select select{
    
    height: 2rem;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0 1rem;
    margin-bottom: 1rem;
}
