.wizard.wizard-6 .wizard-content .wizard-nav {
    padding: 0
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step {
    padding: .75rem 0;
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    margin-bottom: 1.5rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    min-width: 38px;
    height: 38px;
    border-radius: 12px;
    background-color: #f1faff;
    margin-right: 1rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
    display: none;
    font-size: 1.4rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
    font-weight: 600;
    color: #009ef7;
    font-size: 1.35rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #181c32;
    font-weight: 600;
    font-size: 1.24rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
    color: #b5b5c3;
    font-size: .925rem
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    background-color: #009ef7
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-check {
    color: white;
    display: none
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-number {
    color: white
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
    color: #181c32
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-desc {
    color: #b5b5c3
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:last-child .wizard-icon,
.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon {
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    background-color: #5cc1fa
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:last-child .wizard-icon .wizard-check,
.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
    color: white;
    display: inline-block
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:last-child .wizard-icon .wizard-number,
.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-number {
    display: none
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:last-child .wizard-label .wizard-title,
.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title {
    color: #b5b5c3
}

.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current]:last-child .wizard-label .wizard-desc,
.wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-desc {
    color: #d1d3e0
}

@media (max-width:767.98px) {
    .wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step {
        width: 100%
    }

    .wizard.wizard-6 .wizard-content .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .svg-icon {
        display: none
    }
}
.wizard.wizard-6 [data-wizard-type=step-content][data-wizard-state=current]{
    display: block;
}
.wizard.wizard-6 [data-wizard-type=step-content]{
    display: none;
}