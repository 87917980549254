.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.container>div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
}
.containerButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.containerButtons>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}



.sendButton {
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.sendButton:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.sendButton:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.cancelButton {
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
    background: #F26C4F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.cancelButton:hover {
    background: #F26C4F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;
}

.cancelButton:active {
    background: #F26C4F;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.deleteFuncionalidad{
    color: red;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.textFuncionalidad{
    padding: 0.4rem;
    background-color: #3A89C9;
    color: white;
    border-radius: 10px;
}

.containerFuncionalidad{
    margin-top: 1rem;
}