.animation{
    &__heart{
        animation: heartbeat 5s infinite;
    }
    &__comment{
        animation: comment 5s infinite;
    }
    &__people{
        animation: people 10s infinite;
    }
}

@keyframes heartbeat {
    0% { transform: scale( 1 );  opacity: .25; }
    20% { transform: scale( 1.25 ); }
    40% { transform: scale( 1 ); }
    60% { transform: scale( 1.25 ); }
    80% { transform: scale( 1 ); }
    100% { transform: scale( 1.25 ); }
}

@keyframes comment {
    0% { opacity: .5; }
    25% { opacity: 1; }
    50% { opacity: .5; }
    75% { opacity: 1; }
    100% { opacity: .5; }
}

@keyframes people{
    0% { opacity: 0.1; display: none; }
    25% { opacity: 1; }
    50% { opacity: 0.1; display: none; }
    75% { opacity: 1; }
    100% { opacity: 0.1; display: none; }
}