.container {
    display: flex;
    align-items: center;
    width: 100px;
}

.pendiente{
    padding: 2px;
    background: rgba(255, 213, 73, 0.75);
    border: none;
    width: 100%;
    border-radius: 8px;
}

.autorizado{
    padding: 2px;
    background: rgba(22, 147, 165, 0.75);
    border: none;
    width: 100%;
    border-radius: 8px;
}

.rechazado{
    padding: 2px;
    background: rgba(242, 108, 79, 0.75);
    border: none;
    width: 100%;
    border-radius: 8px;
}

.autorizar{
    width: 6.5rem;
    height: 2rem;
    background: #FFD549;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 35% 5%;
}

.autorizar:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.autorizar:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.rechazar {
    width: 6.5rem;
    height: 2rem;
    background: #FFD549;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 35% 5%;
}

.rechazar:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.rechazar:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}